<template>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <!-- Profile Image -->
                <div class="card card-info card-outline">
                    <div class="card-body box-profile p-2">
                        <form class="form-horizontal" name="profileFrom" id="profileFrom" method="post" enctype="multipart/form-data">

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="img-thumbnail upload_image">
                                        <img class="img-fluid" :src="custom_photo">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mt-3">
                                <div class="col-md-12">
                                    <div class="btn btn-light btn-file" style="width: 100%;">
                                        <i class="fa fa-picture-o"></i> Set Profile Image
                                        <input accept="image/*" type="file" name="file" @change="filePreview( $event )">
                                    </div>
                                    <span id="invalid-file" class="invalid-response text-danger"></span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-12">
                                    <button type="button" class="btn btn-info btn-block" :disabled="submitFile" v-on:click="submitImage()"><i class="fa fa-upload"></i> Upload</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
            <div class="col-md-9">
                <div class="card">
                    <div class="card-header p-2">
                        <ul class="nav nav-pills">
                            <li class="nav-item"><a class="nav-link active show" href="#settings" data-toggle="tab">Settings</a></li>
                            <li class="nav-item"><a class="nav-link" href="#change-password" data-toggle="tab">Change Password</a></li>
                        </ul>
                    </div><!-- /.card-header -->
                    <div class="card-body">
                        <div class="tab-content">
                            <!-- Setting Tab -->
                            <div class="tab-pane active show" id="settings">
                                <form @submit.prevent="updateInfo()" class="form-horizontal">
                                    <div class="form-group">
                                        <label for="firstName" class="col-sm-2 control-label">First Name</label>
                                        <div class="col-sm-12">
                                            <input type="text" v-model="form.firstName" class="form-control" id="firstName" placeholder="Enter First Name" :class="{ 'is-invalid': form.errors.has('firstName') }">
                                            <has-error :form="form" field="firstName"></has-error>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="lastName" class="col-sm-2 control-label">Last Name</label>
                                        <div class="col-sm-12">
                                            <input type="" v-model="form.lastName" class="form-control" id="lastName" placeholder="Enter Last Name" :class="{ 'is-invalid': form.errors.has('lastName') }">
                                            <has-error :form="form" field="lastName"></has-error>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="email" class="col-sm-2 control-label">Email</label>
                                        <div class="col-sm-12">
                                            <input type="email" v-model="form.email" class="form-control" id="email" placeholder="Enter Email" readonly :class="{ 'is-invalid': form.errors.has('email') }">
                                            <has-error :form="form" field="email"></has-error>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-2 control-label">Designation</label>
                                        <div class="col-sm-12">
                                            <select name="idDesignations" v-model="form.idDesignations" id="idDesignations" class="form-control" :class="{ 'is-invalid': form.errors.has('idDesignations') }">
                                                <option value="">Select Designation</option>
                                                <option v-for="(designation,index) in designations" :key="index" :value="index" :selected="index == designation.idDesignations">{{ designation }}</option>
                                            </select>
                                            <has-error :form="form" field="idDesignations"></has-error>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-2 control-label">Department</label>
                                        <div class="col-sm-12">
                                            <select name="idDepartments" v-model="form.idDepartments" id="idDepartments" class="form-control" :class="{ 'is-invalid': form.errors.has('idDepartments') }">
                                                <option value="">Select Department</option>
                                                <option v-for="(department,index) in departments" :key="index" :value="index" :selected="index == department.idDepartments">{{ department }}</option>
                                            </select>
                                            <has-error :form="form" field="idDepartments"></has-error>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-2 control-label">Company</label>
                                        <div class="col-sm-12">
                                            <select name="idCompanies" v-model="form.idCompanies" id="idCompanies" class="form-control" :class="{ 'is-invalid': form.errors.has('idCompanies') }">
                                                <option value="">Select Company</option>
                                                <option v-for="(company,index) in companies" :key="index" :value="index" :selected="index == company.idCompanies">{{ company }}</option>
                                            </select>
                                            <has-error :form="form" field="idCompanies"></has-error>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-md-12">
                                            <button type="submit" class="btn btn-success">Update Profile</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <!-- Setting Tab -->
                            <div class="tab-pane" id="change-password">
                                <form class="form-horizontal">
                                    <div class="form-group">
                                        <label for="currentPassword" class="col-sm-3 control-label">Current Password</label>

                                        <div class="col-sm-12">
                                            <input type="password" v-model="form.currentPassword" class="form-control" id="currentPassword" placeholder="Current Password" :class="{ 'is-invalid': form.errors.has('currentPassword') }">
                                            <has-error :form="form" field="currentPassword"></has-error>

                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="newPassword" class="col-sm-3 control-label">New Password</label>

                                        <div class="col-sm-12">
                                            <input type="password" v-model="form.newPassword" class="form-control" id="newPassword" placeholder="New Password" :class="{ 'is-invalid': form.errors.has('newPassword') }">
                                            <has-error :form="form" field="newPassword"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="confirmPassword" class="col-sm-3 control-label">Confirm Password</label>

                                        <div class="col-sm-12">
                                            <input type="password" v-model="form.confirmPassword" class="form-control" id="confirmPassword" placeholder="Confirm Password" :class="{ 'is-invalid': form.errors.has('confirmPassword') }">
                                            <has-error :form="form" field="confirmPassword"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-sm-offset-2 col-sm-12">
                                            <button @click.prevent="updatePassword" type="submit" class="btn btn-success">Update Password</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- /.tab-pane -->
                        </div>
                        <!-- /.tab-content -->
                    </div><!-- /.card-body -->
                </div>
                <!-- /.nav-tabs-custom -->
            </div>
            <!-- end tabs -->
        </div>
    </div>
</section>
</template>

<script>
import ApiService from "../common/api.service";
export default {
    data() {
        return {
            form: new Form({
                id: '',
                firstName: '',
                lastName: '',
                email: '',
                idDesignations: '',
                idDepartments: '',
                idCompanies: '',
                photo: '',
                password: ''
            }),
            file:null,
            custom_photo:null,
            submitFile:true,
            companies: [],
            designations: [],
            departments: [],
        }
    },
    mounted() {
        console.log('Component mounted.')
    },
    methods: {
        filePreview( event ){

            this.submitFile = false;
            this.file = event.target.files[0];

            if (event.target.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    $('.upload_image' + ' img').attr('src', e.target.result);
                };
                reader.readAsDataURL(event.target.files[0]);
            }
            $('.upload_image').show();

        },
        async submitImage() {
            if (this.file === null) {
                Toast.fire({
                    icon: 'error',
                    title: 'Profile photo must be selected!'
                });
                return false;
            }

            this.$Progress.start();
            this.submitFile = true;

            const self = this;
            let formData = new FormData();
            /*
                Iteate over any file sent over appending the files
                to the form data.
            */
            formData.append("id", this.form.id);
            formData.append('file', this.file);
            await axios.post("api/profile/photo-upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    onUploadProgress: function (progressEvent) {
                        var percentCompleted = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                    }
                })
                .then(({data}) => {
                    this.file = null;

                    let userInfo = window.localStorage.getItem('userInfo');
                    userInfo = JSON.parse(userInfo);
                    userInfo.custom_photo = data.data.custom_photo;
                    userInfo.photo = data.data.photo;
                    window.localStorage.setItem('userInfo', JSON.stringify(userInfo));

                    Toast.fire({
                        icon: 'success',
                        title: 'Upload succeeded'
                    });
                })
                .catch(({error}) => {
                    self.submitFile = false;

                    Toast.fire({
                        icon: "error",
                        title: "Upload Error",
                        text: "Something went worng from our end!"
                    });
                });

        },
        updateInfo() {
            this.$Progress.start();
            if (this.form.password == '') {
                this.form.password = undefined;
            }
            this.form.put('api/profile')
                .then((data) => {
                    this.$Progress.finish();
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                })
                .catch((data) => {
                    this.$Progress.fail();

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                });
        },

        updatePassword() {
            this.$Progress.start();
            this.form.post('api/change-password')
                .then((data) => {
                    //  Fire.$emit('AfterCreate');
                    this.$Progress.finish();
                    this.form.currentPassword = '';
                    this.form.newPassword = '';
                    this.form.confirmPassword = '';

                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                })
                .catch(() => {
                    this.$Progress.fail();

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                });
        },
        getCompanies() {
            ApiService.setHeader();
            ApiService.get("api/user/compaines")
                .then(({
                    data
                }) => {
                    this.companies = data
                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                });

        },
        getDesignations() {
            ApiService.setHeader();
            ApiService.get("api/user/designations")
                .then(({
                    data
                }) => {
                    this.designations = data
                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                });

        },
        getDepartments() {
            ApiService.setHeader();
            ApiService.get("api/user/departments")
                .then(({
                    data
                }) => {
                    this.departments = data
                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                });

        },
    },

    created() {
        console.log(process.env.MIX_AWS_URL);
        this.getCompanies();
        this.getDesignations();
        this.getDepartments();

        this.$Progress.start();
        ApiService.setHeader();
        ApiService.get("api/profile")
            .then(({
                data
            }) => {

                let userData = data.data;
                userData.idDesignations = userData.idDesignations === null ? '' : userData.idDesignations;
                userData.idDepartments = userData.idDepartments === null ? '' : userData.idDepartments;
                userData.idCompanies = userData.idCompanies === null ? '' : userData.idCompanies;
                this.custom_photo = (userData.photo) ? process.env.MIX_AWS_URL+'uploads/users/'+userData.photo : userData.custom_photo;
                this.form.fill(userData)
            })
            .catch(({
                response
            }) => {

            });

        this.$Progress.finish();
    }
}
</script>
