<template>
    <div>
        <div class="login-logo">
            <img v-bind:src="'images/logo.png'" >
        </div>
        <div class="card">
            <div class="card-body login-card-body">
                <p class="login-box-msg">Sign in to start your session</p>
                <alert-error :form="form" field="error"></alert-error>
                <form @submit.prevent="loginSubmit" autocomplete="off">
                    <div class="input-group mb-3">
                        <input type="email" class="form-control" :class="{ 'is-invalid': form.errors.has('email') }" v-model="form.email" placeholder="Email" autocomplete="off">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                        <has-error :form="form" field="email"></has-error>
                    </div>
                    <div class="input-group mb-3">
                        <input v-if="showPassword" type="text" class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" v-model="form.password" placeholder="Password" autocomplete="off" />
                        <input v-else type="password" class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" v-model="form.password" placeholder="Password" autocomplete="off">
                        <div class="input-group-append">
                            <div class="input-group-text"  @click="toggleShow">
                                <span class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></span>
                            </div>
                        </div>
                        <has-error :form="form" field="password"></has-error>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <div class="icheck-primary">
                                <input type="checkbox" id="remember" v-model="form.remember">
                                <label for="remember">
                                    Remember Me
                                </label>
                            </div>
                        </div>
                        <!-- /.col -->
                        <div class="col-4">
                            <button type="submit" class="btn btn-primary btn-block">Sign In</button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>

                <p class="mb-1">
                    <a href="#">I forgot my password</a>
                </p>
            </div>
            <!-- /.login-card-body -->
        </div>
    </div>
</template>

<script>
    import JwtService from "../common/jwt.service";
    import store from '../store'

    export default {
        data () {
            return {
                showPassword: false,
                form: new Form({
                    email : '',
                    password: '',
                    remember: false
                })
            }
        },
        computed: {
            buttonLabel() {
                return (this.showPassword) ? "Hide" : "Show";
            }
        },
        methods: {
            toggleShow() {
                this.showPassword = !this.showPassword;
            },
            loginSubmit(){
                this.$Progress.start();

                this.form.post('api/auth/login')
                .then((data)=>{

                    JwtService.saveToken(data.data.access_token);
                    window.localStorage.setItem('userInfo', JSON.stringify(data.data.user));
                    window.localStorage.setItem('settings', JSON.stringify({fileView:'col', 'extensions' : JSON.stringify(data.data.extensions)}));
                    JwtService.saveToken(data.data.access_token);
                    store.commit('loginUser')
                    window.location.href = "/dashboard"
                    //   this.$router.push({ name: 'dashboard' })
                    //   window.location.reload()
                    window.user = data.data.user;
                    Toast.fire({
                        icon: 'success',
                        title: 'You are logged in!'
                    });
                    this.$Progress.finish();
                })
                .catch(()=>{

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            }

        },
        mounted() {

        },
        created() {
            this.$Progress.start();

            this.$Progress.finish();
        }
    }
</script>
