var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 col-md-3"},[_c('div',{staticClass:"info-box"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"info-box-content"},[_c('span',{staticClass:"info-box-text"},[_vm._v("Total Files")]),_vm._v(" "),_c('span',{staticClass:"info-box-number"},[_vm._v(_vm._s(_vm.totalFiles))])])])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-6 col-md-3"},[_c('div',{staticClass:"info-box mb-3"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"info-box-content"},[_c('span',{staticClass:"info-box-text"},[_vm._v("This Year")]),_vm._v(" "),_c('span',{staticClass:"info-box-number"},[_vm._v(_vm._s(_vm.thisYearCountFiles))])])])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-6 col-md-3"},[_c('div',{staticClass:"info-box mb-3"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"info-box-content"},[_c('span',{staticClass:"info-box-text"},[_vm._v("This Month")]),_vm._v(" "),_c('span',{staticClass:"info-box-number"},[_vm._v(_vm._s(_vm.thisMonthCount))])])])]),_vm._v(" "),_c('div',{staticClass:"clearfix hidden-md-up"}),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-6 col-md-3"},[_c('div',{staticClass:"info-box mb-3"},[_vm._m(3),_vm._v(" "),_c('div',{staticClass:"info-box-content"},[_c('span',{staticClass:"info-box-text"},[_vm._v("Today")]),_vm._v(" "),_c('span',{staticClass:"info-box-number"},[_vm._v(_vm._s(_vm.todayFileCount))])])])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"table-responsive"},[_c('vue-good-table',{attrs:{"mode":"remote","title":"Timelines","styleClass":"vgt-table bordered vgt-table-custom","totalRows":_vm.totalRecords,"columns":_vm.columns,"rows":_vm.rows,"isLoading":_vm.isLoading,"search-options":{
                            enabled: true,
                            trigger: 'enter',
                            skipDiacritics: true,
                            placeholder: 'Search this table'
                        },"pagination-options":{
                            enabled: true,
                            mode: 'records'
                        },"sort-options":{
                            enabled: true,
                            initialSortBy: {
                                field: 'idActionTimelines',
                                type: 'ASC'
                            }
                        }},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-search":_vm.onSearch},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[(props.row.idFolders)?_c('router-link',{attrs:{"to":'/my-files?id_folders=' + props.row.idFolders}},[_c('i',{staticClass:"fa fa-info-circle"})]):(props.row.idFiles && props.row.fileIdFolders)?_c('router-link',{attrs:{"to":'/my-files?id_folders=' + props.row.fileIdFolders}},[_c('i',{staticClass:"fa fa-info-circle"})]):_c('router-link',{attrs:{"to":'/my-files'}},[_c('i',{staticClass:"fa fa-info-circle"})])],1):_vm._e()]}}])})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"info-box-icon bg-info elevation-1"},[_c('i',{staticClass:"fas fa-file"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"info-box-icon bg-warning elevation-1"},[_c('i',{staticClass:"fas fa-file"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"info-box-icon bg-danger elevation-1"},[_c('i',{staticClass:"fas fa-file"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"info-box-icon bg-success elevation-1"},[_c('i',{staticClass:"fas fa-file"})])
}]

export { render, staticRenderFns }