/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
import moment from 'moment';

import store from './store'

import App from './App.vue';
import { Form, HasError, AlertError } from 'vform';
window.Form = Form;

import Gate from "./Gate";
Vue.prototype.$gate = new Gate(window.user);

import Swal from 'sweetalert2';

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

import ApiService from "./common/api.service";

import VueGoodTablePlugin from 'vue-good-table';
import JwtService from "./common/jwt.service";

// import the styles
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);

// API service init
ApiService.init();

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
window.Swal = Swal;
window.Toast = Toast;

import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '3px'
  });

Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)


/**
 * Routes imports and assigning
 */
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import routes from './routes';

const router = new VueRouter({
    mode: 'history',
    routes
});
// Routes End


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


// Components
Vue.component('pagination', require('laravel-vue-pagination'));
// Vue.component('dashboard', require('./components/Dashboard.vue'));
// Vue.component('sidebar-menu', require('./components/SidebarMenu.vue').default);

Vue.component(
    'not-found',
    require('./components/NotFound.vue').default
);

// Filter Section

Vue.filter('myDate',function(created){
    return moment(created).format('MMMM Do YYYY');
});

Vue.filter('customDateFormate',function(created){
    return moment(created).format('DD/MM/YYYY');
});

Vue.filter('customDateTime',function(created){
    return moment(created).format('DD/MM/YYYY h:mm a')
});

Vue.filter('icon',function(extension){
    var icon = 'fa-file';
    if(extension.toUpperCase()==='CSV'){
        icon = 'fa-file-csv';
    }else if(extension.toUpperCase()==='DOC' || extension.toUpperCase()==='DOCX'){
        icon = 'fa-file-word';
    }else if(extension.toUpperCase()==='PPT' || extension.toUpperCase()==='PPTX'){
        icon = 'fa-file-powerpoint';
    }else if(extension.toUpperCase()==='XLS' || extension.toUpperCase()==='XLSX'){
        icon = 'fa-file-excel';
    }else if(extension.toUpperCase()==='ZIP'){
        icon = 'fa-file-zip-o';
    }else if(extension.toUpperCase()==='PDF'){
        icon = 'fa-file-pdf';
    }else if(extension.toUpperCase()==='JPG' || extension.toUpperCase()==='JPEG' || extension.toUpperCase()==='PNG' || extension.toUpperCase()==='GIF'){
        icon = 'fa-file-image';
    }

    return 'far '+icon;
});

// This is a global mixin, it is applied to every vue instance
Vue.mixin({
    data: function() {
        return {
            publicPath: process.env.MIX_BASE_URL,
            AWS_URL: process.env.MIX_AWS_URL
        }
    },
    methods:{
        showExtension(value){

            if(value.toUpperCase()==='FOLDER'){
                return 'uploads/extensions/folder.png';
            }

            let extensions = JSON.parse(JSON.parse(localStorage.getItem("settings")).extensions);
            const result = extensions.find( ({ type }) => type.toUpperCase() === value.toUpperCase() );

            if(result){
                return 'uploads/extensions/'+result.image;
            }else {
                let defult = extensions.find( ({ type }) => type.toUpperCase() == 'DEFULT' );
                return 'uploads/extensions/'+defult.image;
            }
        },
        showStatus(status){

            if(status.toUpperCase()==='BLANK'){
                return process.env.MIX_BASE_URL+'images/status/blank.svg';
            }else if(status.toUpperCase()==='PARTIAL' ){
                return process.env.MIX_BASE_URL+'images/status/partial.svg';
            }else if(status.toUpperCase()==='FULL'){
                return process.env.MIX_BASE_URL+'images/status/full.svg';
            }
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }
})

Vue.filter('yesno', value => (value ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>'));

// end Filter

Vue.component('example-component', require('./components/ExampleComponent.vue'));


router.beforeEach((to, from, next) => {

    //checks the token expiration and logs the user out if it's expired.
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        const token = JwtService.getToken(); // Change this to your storage method
        if (token && JwtService.isTokenExpired(token)) {
            
            JwtService.destroyToken();
            window.localStorage.removeItem("userInfo");
            store.commit('logoutUser')
            Toast.fire({
                icon: 'info',
                title: 'Your session has been expired. Please login again.'
            });
            
            // Redirect to another route programmatically
            // Token is expired or not available, redirect to the login page
            window.location.href = "/login"
            return 
        }
        
    }

    // check if the route requires authentication and user is not logged in
    if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isLoggedIn) {
        // redirect to login page
        window.location.href = "/login"
        // return next({ name: 'login' })
        return
    }

    // if logged in redirect to dashboard
    if(to.path === '/login' && store.state.isLoggedIn) {
        // window.location.href = "/dashboard"
        return next({ name: 'dashboard' })
        // return
    }

    next()
})


const app = new Vue({
    el: '#app',
    router,
    render: h => h(App),
});
