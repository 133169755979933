<template>
<section class="content">
    <div class="row">
        <div class="col-8" :class="{'col-md-12':!showFileDetail}">
            <breadcrumb :breadcrumbs="breadcrumbs" :currentId="currentId" @loadFileFolders="loadFileFolders"  @previousInfo="previousInfo"></breadcrumb>

            <div class="row">
                <div class="col-12">
                    <div class="file-manager-actions container-p-x py-2 float-right">
                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                            <label class="btn btn-default md-btn-flat active"> <input type="radio" name="file-manager-view" value="file-manager-col-view" checked="" @click="fileManagerView('col')"/> <span class="fa fa-columns"></span> </label>
                            <label class="btn btn-default md-btn-flat"> <input type="radio" name="file-manager-view" value="file-manager-row-view" @click="fileManagerView('row')"/> <span class="fa fa-bars"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="container-fluid flex-grow-1 light-style" style="background-color: white;">
                        <div class="container-m-nx container-m-ny bg-lightest pt-3 pb-3">
                            <div class="file-manager-container" :class="fileView=='col' ? 'file-manager-col-view' : 'file-manager-row-view'">
                                <div class="file-manager-row-header">
                                    <div class="file-item-name pb-2">Filename</div>
                                    <div class="file-item-size pb-2">File Size</div>
                                    <div class="file-item-changed pb-2">Last Modified</div>
                                </div>

                                <div class="file-item" v-for="folder in folders" :key="folder.idFolders">
                                    <div class="file-item-select-bg bg-primary"></div>
                                    <!--<label class="file-item-checkbox custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" v-model="folder.isSelected" />
                                        <span class="custom-control-label"></span>
                                    </label>-->
                                    <div class="file-item-icon far fa-folder text-secondary" @click="next(folder)"></div>
                                    <a @click="next(folder)" href="javascript:void(0)" class="file-item-name">
                                        {{folder.name | truncate(35, '')}}
                                    </a>
                                    <div class="file-item-size">-</div>
                                    <div class="file-item-changed">{{folder.createdAt | customDateFormate}}</div>
                                    <div class="btn-group dropdownbtn">
                                        <button type="button" class="btn btn-default btn-sm rounded-pill icon-btn borderless md-btn-flat hide-arrow dropdown-toggle" data-toggle="dropdown"><i class="fa fa-ellipsis-h"></i></button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" href="javascript:void(0)" @click="restore(folder.idFolders, 'folders')">Restore</a>
                                            <a class="dropdown-item" href="javascript:void(0)" @click="deleteFolder(folder.idFolders)">Delete Forever</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="file-item" v-for="(file, index)  in files" :key="'file-'+index">
                                    <div class="file-item-select-bg bg-primary"></div>
                                    <div class="file-item-icon text-secondary" @click="showFileDetails(file)" v-bind:style="{ background: 'url(' + showExtension(file.fileExtension) + ') no-repeat center center' }"></div>
                                    <a @click="showFileDetails(file)" href="javascript:void(0)" class="file-item-name" :title="file.originalFileName">
                                        {{file.originalFileName | truncate(35, '...')}}
                                    </a>
                                    <div class="file-item-size">{{bytesToSize(file.fileSize)}}</div>
                                    <div class="file-item-changed">{{file.createdAt | customDateTime}}</div>
                                    <div class="btn-group dropdownbtn">
                                        <button type="button" class="btn btn-default btn-sm rounded-pill icon-btn borderless md-btn-flat hide-arrow dropdown-toggle" data-toggle="dropdown"><i class="fa fa-ellipsis-h"></i></button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" href="javascript:void(0)" @click="restore(file.idFiles, 'files')">Restore</a>
                                            <a class="dropdown-item" href="javascript:void(0)" @click="removeFile(file.idFiles)">Delete Forever</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4" v-if="showFileDetail">
            <file-details :file="fileInfo" :page="'trash'" @loadFileFolders="loadFileFolders" ref="fileDetailRef"></file-details>
        </div>
    </div>
</section>
</template>

<script>
import ApiService from "../common/api.service";
import FileDetails  from './FileDetails.vue';
import Breadcrumb  from './Breadcrumb.vue';
export default {
    // props: [],
    components: {FileDetails, Breadcrumb},
    data() {
        return {
            fileView:(localStorage.getItem("settings")) ? JSON.parse(localStorage.getItem("settings")).fileView : 'col',
            folders: [],
            files: [],
            breadcrumbs: [],
            selectedInfo:{},
            currentId: null,
            previousId: null,
            search: null,
            showFileDetail:false,
            fileInfo:{}
        }
    },
    filters: {
        truncate: function (text, length, suffix) {
            return text.substring(0, length) + suffix;
        },
    },
    methods: {
        showFileDetails(file){
            this.showFileDetail = true;
            this.fileInfo = file;
            var self = this;
            setTimeout(function() {
                self.$refs.fileDetailRef.getFileDetails();
            }, 300);

        },
        fileManagerView(view){
            const settings = JSON.parse(localStorage.getItem('settings'));
            settings.fileView = view;
            localStorage.setItem('settings', JSON.stringify(settings));
            this.fileView = view;
        },
        bytesToSize(bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            if (bytes === 0) return '-'
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },
        next(folderInfo){

            Swal.fire(
                'This folder is in your trash',
                'To view this folder, you\'ll need to restore it from your trash.',
                'info'
            );
            return false;

            this.$Progress.start();
            this.selectedInfo = folderInfo;
            this.currentId = folderInfo.idFolders;
            if(folderInfo.parentId!=null){
                this.previousId = folderInfo.parentId;
            }else{
                this.previousId = null;
            }

            this.loadFileFolders(this.currentId);

            this.$Progress.finish();
        },
        previousInfo(selectedId){
            this.showFileDetail = false;
            this.currentId = selectedId;
            this.previousId = null;
            if(selectedId){
                this.currentDirectoryInfo(selectedId);
            }else{
                this.selectedInfo = {};
                this.currentId = null;
            }
        },
        loadFileFolders(parentId = '', searchText = '') {
            this.showFileDetail = false;
            let params = { parentId: parentId, searchText: searchText }
            ApiService.setHeader();
            ApiService.post("api/trash", params)
                .then(({data}) => {

                    this.folders = data.data.folders;
                    this.files = data.data.files;
                    this.breadcrumbs = data.data.breadcrumbs;
                })
                .catch(({response}) => {

                });

        },

        currentDirectoryInfo(id){
            ApiService.get("api/folder/"+id)
                .then(({data}) => {

                    this.selectedInfo = data.data;
                })
                .catch(({response}) => {
                    console.log(response);
                });
        },

        deleteFolder(id) {
            let self = this;
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to restore it.",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Delete forever'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    ApiService.delete('api/trash/folder/'+id).then(()=>{
                        Swal.fire(
                            'Deleted!',
                            'Folder has been deleted forever.',
                            'success'
                        );

                        let parentId = (self.selectedInfo.hasOwnProperty('idFolders')) ? self.selectedInfo.idFolders : null;
                        self.loadFileFolders(parentId);
                    }).catch((data)=> {
                        Swal.fire("Failed!", 'Some error occured! Please try again', "warning");
                    });

                }
            })
        },
        removeFile(id) {
            let self = this;
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to restore it.",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Delete forever'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    ApiService.delete('api/trash/file/'+id).then(()=>{
                        Swal.fire(
                            'Deleted!',
                            'File has been deleted forever.',
                            'success'
                        );

                        self.loadFileFolders(self.currentId);
                    }).catch((data)=> {
                        Swal.fire("Failed!", data.message, "warning");
                    });
                }
            })
        },

        restore(id, type) {
            let params = { id: id, type: type }
            ApiService.post("api/restore", params)
                .then(({data}) => {
                    let name = (type==='folders') ? data.data.name: data.data.file_name;
                    Swal.fire(
                        'Restored!',
                        'Restored '+name,
                        'success'
                    );

                    let parentId = (this.selectedInfo.hasOwnProperty('idFolders')) ? this.selectedInfo.idFolders : null;
                    this.loadFileFolders(parentId);
                })
                .catch(({response}) => {
                    Swal.fire("Failed!", 'Some error occured! Please try again', "warning");
                });


        },
    },
    mounted() {
        console.log("mounted");
    },
    created() {
        this.$Progress.start();
        this.loadFileFolders();
        this.$Progress.finish();
    }
}
</script>
<style src="./file_manager_style.css"></style>
