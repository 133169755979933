<style>
    .navbar-light .form-control-navbar:focus, .navbar-light .form-control-navbar:focus + .input-group-append .btn-navbar {
        background-color: rgb(255 255 255 / 80%);
        border-color: #f2f2f3 !important;
        color: #212529;
    }
</style>
<template>
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand bg-white navbar-light border-bottom">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#"><i class="fa fa-bars"></i></a>
            </li>
        </ul>
        <!-- SEARCH FORM -->
        <form class="form-inline ml-3" action="search">
            <div class="input-group input-group-sm">
                <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" name="q" autofocus v-model="keyword" style="width: 200px;">
                <div class="input-group-append">
                    <button class="btn btn-navbar" type="submit">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
            </div>
        </form>
        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto">

            <li class="nav-item dropdown user-menu">
                <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
                    <img :src="AWS_URL+'uploads/users/'+photo" class="img-circle elevation-2" alt="User Image" style="height: auto; width: 1.8rem;">
                    <span class="d-none d-md-inline">{{name}}</span>
                </a>
                <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="margin-top: 7px">
                    <!-- User image -->
                    <li class="user-header bg-primary" style="height:80px;">
                        <p>
                            {{name}} - {{designation_name}}
                            <small>Member since: {{created_at|myDate}}</small>
                        </p>
                    </li>
                    <!-- Menu Footer-->
                    <li class="user-footer">
                        <router-link to="/profile" class="btn btn-default btn-flat">Profile</router-link>
                        <router-link to="/logout" class="btn btn-default btn-flat float-right">Logout</router-link>
                    </li>
                </ul>
            </li>

        </ul>

    </nav>
    <!-- /.navbar -->
</template>

<script>
import store from '../../store'
export default {
    data(){
        return {
            keyword:this.$route.query.q,
            name: store.state.userInfo.first_name+' '+store.state.userInfo.last_name,
            email: store.state.userInfo.email,
            photo: store.state.userInfo.photo ?? 'default.png',
            role: store.state.userInfo.role_name,
            department_name: store.state.userInfo.department_name,
            designation_name: store.state.userInfo.designation_name,
            company_name: store.state.userInfo.company_name,
            created_at: store.state.userInfo.created_at,
        }
    },
    methods: {

    }
}
</script>

