<style>
    .table thead tr th,
    .table tbody tr td {
        vertical-align: middle;
    }
</style>
<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">

            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header">
                <h3 class="card-title">User List</h3>

                <div class="card-tools">

                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Photo</th>
                      <th>Role</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Designation</th>
                      <th>Department</th>
                      <th>Company</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="user in users" :key="user.id">

                      <td>{{user.id}}</td>
                      <td>
                        <img class="img-responsive image-thumbnail"
                            :src="(user.photo) ? AWS_URL+'uploads/users/'+user.photo : user.custom_photo"
                            :alt="user.firstName+' '+user.lastName"
                            style="max-width: 70px;max-height: 70px;">
                      </td>
                      <td class="text-capitalize">{{user.role.name}}</td>
                      <td class="text-capitalize">{{user.firstName+' '+user.lastName}}</td>
                      <td>{{user.email}}</td>
                      <td>{{(user.designation!=null) ? user.designation.name : ''}}</td>
                      <td>{{(user.department!=null) ? user.department.name : ''}}</td>
                      <td>{{(user.company!=null) ? user.company.name : ''}}</td>
                      <!-- <td :inner-html.prop="user.email_verified_at | yesno"></td> -->
                      <td>

                        <a href="#" @click="editModal(user)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteUser(user.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <!-- <pagination :data="users" @pagination-change-page="getResults"></pagination> -->
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New User</h5>
                    <h5 class="modal-title" v-show="editmode">Update User's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateUser() : createUser()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>First Name</label>
                            <input v-model="form.firstName" type="text" name="firstName"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('firstName') }">
                            <has-error :form="form" field="firstName"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Last Name</label>
                            <input v-model="form.lastName" type="text" name="lastName"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('lastName') }">
                            <has-error :form="form" field="lastName"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input v-model="form.email" type="text" name="email"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                            <has-error :form="form" field="email"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Password</label>
                            <input v-model="form.password" type="password" name="password"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" autocomplete="false">
                            <has-error :form="form" field="password"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Designation</label>
                            <select name="idDesignations" v-model="form.idDesignations" id="idDesignations" class="form-control" :class="{ 'is-invalid': form.errors.has('idDesignations') }">
                                <option value="">Select Designation</option>
                                <option
                                  v-for="(designation,index) in designations" :key="index"
                                  :value="index"
                                  :selected="index == designation.idDesignations">{{ designation }}</option>
                            </select>
                            <has-error :form="form" field="idDesignations"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Department</label>
                            <select name="idDepartments" v-model="form.idDepartments" id="idDepartments" class="form-control" :class="{ 'is-invalid': form.errors.has('idDepartments') }">
                                <option value="">Select Department</option>
                                <option
                                  v-for="(department,index) in departments" :key="index"
                                  :value="index"
                                  :selected="index == department.idDepartments">{{ department }}</option>
                            </select>
                            <has-error :form="form" field="idDepartments"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Company</label>
                            <select name="idCompanies" v-model="form.idCompanies" id="idCompanies" class="form-control" :class="{ 'is-invalid': form.errors.has('idCompanies') }">
                                <option value="">Select Company</option>
                                <option
                                  v-for="(company,index) in companies" :key="index"
                                  :value="index"
                                  :selected="index == company.idCompanies">{{ company }}</option>
                            </select>
                            <has-error :form="form" field="idCompanies"></has-error>
                        </div>
                        <div class="form-group">
                            <label>User Role</label>
                            <select name="idRole" v-model="form.idRole" id="idRole" class="form-control" :class="{ 'is-invalid': form.errors.has('idRole') }">
                                <option value="">Select User Role</option>
                                <option
                                  v-for="(role,index) in roles" :key="index"
                                  :value="index"
                                  :selected="index == form.idRole">{{ role }}</option>
                            </select>
                            <has-error :form="form" field="idRole"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import ApiService from "../common/api.service";
    export default {
        data () {
            return {
                editmode: false,
                users : [],
                form: new Form({
                    id : '',
                    idRole : '',
                    firstName: '',
                    lastName: '',
                    idDesignations: '',
                    idDepartments: '',
                    idCompanies: '',
                    photo: '',
                    email: '',
                    password: '',
                    emailVerifiedAt: '',
                }),
                roles: [],
                companies: [],
                designations: [],
                departments: [],
            }
        },
        methods: {
            getRoles() {
                ApiService.setHeader();
                ApiService.get("api/user/roles")
                    .then(({ data }) => {
                        this.roles = data
                    })
                    .catch(({ response }) => {
                        console.log(response);
                    });

            },
            getCompanies() {
                ApiService.setHeader();
                ApiService.get("api/user/compaines")
                    .then(({ data }) => {
                        this.companies = data
                    })
                    .catch(({ response }) => {
                        console.log(response);
                    });

            },
            getDesignations() {
                ApiService.setHeader();
                ApiService.get("api/user/designations")
                    .then(({ data }) => {
                        this.designations = data
                    })
                    .catch(({ response }) => {
                        console.log(response);
                    });

            },
            getDepartments() {
                ApiService.setHeader();
                ApiService.get("api/user/departments")
                    .then(({ data }) => {
                        this.departments = data
                    })
                    .catch(({ response }) => {
                        console.log(response);
                    });

            },
            getResults(page = 1) {

                this.$Progress.start();
                // ApiService.setHeader();
                ApiService.get("api/user?page=" + page)
                    .then(({ data }) => {
                        this.users = data.data
                    })
                    .catch(({ response }) => {
                        console.log(response);
                    });

                this.$Progress.finish();
            },
            updateUser(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('api/user/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadUsers();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(user){
                this.editmode = true;
                this.form.reset();
                //Clear all validation error
                this.form.errors.clear(undefined)
                $('#addNew').modal('show');
                user.idDesignations = user.idDesignations===null ? '' : user.idDesignations;
                user.idDepartments = user.idDepartments===null ? '' : user.idDepartments;
                user.idCompanies = user.idCompanies===null ? '' : user.idCompanies;
                this.form.fill(user);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            deleteUser(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('api/user/'+id).then(()=>{
                                    Swal.fire(
                                        'Deleted!',
                                        'User has been deleted.',
                                        'success'
                                    );
                                    // Fire.$emit('AfterCreate');
                                    this.loadUsers();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },
          loadUsers(){
            this.$Progress.start();

            if(this.$gate.isAdmin()){

                ApiService.setHeader();
                ApiService.get("api/user")
                    .then(({ data }) => {
                        this.users = data.data
                        console.log(data.data);
                    })
                    .catch(({ response }) => {
                        console.log(response);
                    });
            }

            this.$Progress.finish();
          },

          createUser(){

              this.form.post('api/user')
              .then((response)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });

                  this.$Progress.finish();
                  this.loadUsers();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          }

        },
        mounted() {
            console.log('User Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.getRoles();
            this.getCompanies();
            this.getDesignations();
            this.getDepartments();
            this.loadUsers();
            this.$Progress.finish();
        }
    }
</script>
