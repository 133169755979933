<template>
<section class="content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12">

                <div class="card" v-if="$gate.isAdmin()">
                    <div class="card-header">
                        <h3 class="card-title">Extension List</h3>

                        <div class="card-tools">

                            <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                <i class="fa fa-plus-square"></i>
                                Add New
                            </button>
                        </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body table-responsive p-0">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Image</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                    <th>Created</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="extension in extensions" :key="extension.id_file_extensions">

                                    <td>{{extension.id_file_extensions}}</td>
                                    <td>
                                        <img class="img-responsive image-thumbnail"
                                            :src="(extension.image) ? 'uploads/extensions/'+extension.image : 'images/no-image.png'"
                                            :alt="extension.type"
                                            style="max-width: 32px;max-height: 32px;">
                                    </td>
                                    <td>{{extension.type=='defult' ? 'default' : extension.type}}</td>
                                    <td>{{extension.description}}</td>
                                    <td>{{extension.created_at | myDate}}</td>
                                    <td>
                                        <a href="#" @click="editModal(extension)">
                                            <i class="fa fa-edit blue"></i>
                                        </a>

                                        <a href="#" @click="deleteExtension(extension.id_file_extensions)" v-if="extension.type!='defult' && extension.type!='folder'">
                                            / <i class="fa fa-trash red"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- /.card-body -->
                    <div class="card-footer">

                    </div>
                </div>
                <!-- /.card -->
            </div>
        </div>

        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-show="!editmode">Create New Extension</h5>
                        <h5 class="modal-title" v-show="editmode">Update Extension</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <form @submit.prevent="editmode ? updateExtension() : createExtension()" enctype="multipart/form-data">
                        <div class="modal-body">
                            <div class="form-group">
                                <label>Type<span class="mandatory">*</span></label>
                                <input v-model="form.type" type="text" name="type" class="form-control" :readonly="editmode && (form.type==='defult' || form.type==='folder')">
                                <span id="invalid-type" class="invalid-response text-danger"></span>
                            </div>
                            <div class="form-group">
                                <label>Description</label>
                                <input v-model="form.description" type="text" name="description" class="form-control">
                            </div>
                            <div class="form-group">
                                <label>Extension Image<span class="mandatory" v-show="!editmode">*</span></label>
                                <input id="image" type="file" name="image" placeholder="Image" @change="selectFile" class="form-control" accept="image/*,.pdf" style="    line-height: 1.3;">
                                <span id="invalid-image" class="invalid-response text-danger"></span>
                                <p><span class="label text-danger">NOTE!</span> Supported file format: jpeg, jpg, png, gif. The maximum file size: 1MB, recommended dimension (32 X 32).</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                            <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import ApiService from "../common/api.service";
export default {
    data() {
        return {
            editmode: false,
            extensions: [],
            // Create a new form instance
            form: {
                id_file_extensions: '',
                type: '',
                description: '',
                image: null
            }
        }
    },
    methods: {
        selectFile(e) {
            const file = e.target.files[0];
            this.form.image = file;
        },
        loadExtensions() {
            ApiService.setHeader();
            ApiService.get("api/extension").then(({data}) => (
                this.extensions = data.data
            ));
        },
        updateExtension() {
            this.$Progress.start();

            let formData = new FormData();
            /*
                Iteate over any file sent over appending the files
                to the form data.
            */
            formData.append('id_file_extensions', this.form.id_file_extensions);
            formData.append('type', this.form.type);
            formData.append('description', this.form.description);
            formData.append('image', (this.form.image!==null) ? this.form.image : '');

            ApiService.post("api/extension/update/"+this.form.id_file_extensions, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(({data}) => {

                $('#addNew').modal('hide');

                Toast.fire({
                    icon: 'success',
                    title: 'File Extension updated Successfully!'
                });
                this.$Progress.finish();
                this.loadExtensions();
            })
            .catch(({ response}) => {
                if (response.status === 422) {
                    $.each(response.data.errors, function(index, value) {
                        index = index.replace('.','-');
                        $('#invalid-' + index).text(value[0]);
                    });

                    Toast.fire({
                        icon: "error",
                        title: response.statusText,
                        text: response.data.message
                    });

                } else {
                    Toast.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!"
                    });
                }
            });

        },
        editModal(extension) {
            this.form = {
                id_file_extensions: extension.id_file_extensions,
                type: extension.type,
                description: extension.description,
                image: null
            };
            this.editmode = true;
            //Clear all validation error
            $('#addNew').modal('show');
        },
        newModal() {
            this.form = {
                id_file_extensions: '',
                type: '',
                description: '',
                image: null
            };
            this.editmode = false;
            $('#addNew').modal('show');
        },
        createExtension() {

            const self = this;
            let formData = new FormData();
            /*
                Iteate over any file sent over appending the files
                to the form data.
            */
            formData.append('type', this.form.type);
            formData.append('description', this.form.description);
            formData.append('image', this.form.image);

            ApiService.post("api/extension", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(({data}) => {
                $('#addNew').modal('hide');
                //Reset form data
                self.form = {
                    id_file_extensions: '',
                    type: '',
                    description: '',
                    image: null
                };

                Toast.fire({
                    icon: 'success',
                    title: 'File Extension Added Successfully!'
                });
                this.$Progress.finish();
                this.loadExtensions();
            })
            .catch(({ response}) => {
                if (response.status === 422) {
                    $.each(response.data.errors, function(index, value) {
                        index = index.replace('.','-');
                        $('#invalid-' + index).text(value[0]);
                    });

                    Toast.fire({
                        icon: "error",
                        title: response.statusText,
                        text: response.data.message
                    });

                } else {
                    Toast.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!"
                    });
                }
            });

        },
        deleteExtension(id) {
            const self = this;
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    ApiService.delete("api/extension/" + id)
                    .then(({ data }) => {
                        Swal.fire(
                            'Deleted!',
                            'Extension has been deleted.',
                            'success'
                        );
                        self.loadExtensions();
                    })
                    .catch(({ response }) => {
                        swal.fire("Failed!", "There was something wrong", "warning");
                    });
                }
            })
        },

    },
    created() {
        this.$Progress.start();

        this.loadExtensions();

        this.$Progress.finish();
    }
}
</script>
