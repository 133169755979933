import jwtDecode from 'jwt-decode';

const ID_TOKEN_KEY = "AccessToken";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

// Function to check if a JWT token is expired.
export const isTokenExpired = (token) => {

  try {
   
    // return true;
    const decoded = jwtDecode(token);
    console.log("jwtDecode", decoded);
    // Check the 'exp' claim in the token payload to determine if it's expired.
    return decoded.exp * 1000 < Date.now();
  } catch (error) {
    console.error("JWT Decoding Error:", error);
    // Handle any decoding errors here.
    return true; // Treat it as expired to be safe.
  }
}

export default { getToken, saveToken, destroyToken, isTokenExpired };
