import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLoggedIn: !!localStorage.getItem('AccessToken'),
        userInfo: (localStorage.getItem("userInfo")) ? JSON.parse(localStorage.getItem("userInfo")) : {}
    },
    mutations: {
        loginUser (state) {
            state.isLoggedIn = true,
            state.userInfo = JSON.parse(localStorage.getItem("userInfo"))
        },
        logoutUser (state) {
            state.isLoggedIn = false,
            state.userInfo = {}
        },
    }
})
