<template>
<section class="content">
    <div class="row">
        <div class="col-8" :class="{'col-md-12':!showFileDetail}">
            <breadcrumb :breadcrumbs="breadcrumbs" :currentId="currentId" @loadFileFolders="loadFileFolders" @previousInfo="previousInfo" :showShareFileFolder="showShareFileFolder"></breadcrumb>

            <div class="row">
                <div class="col-12">
                    <div class="file-manager-actions container-p-x py-2">
                        <div>
                            <button type="button" class="btn btn-primary mr-2" @click="newModal"><i class="fa fa-folder-plus"></i>&nbsp; Folder</button>
                            <file-uploader :selectedInfo="selectedInfo" @loadFileFolders="loadFileFolders" :showShareFileFolder="showShareFileFolder">&nbsp; Upload</file-uploader>
                            <zip-file-uploader :selectedInfo="selectedInfo" @loadFileFolders="loadFileFolders" :showShareFileFolder="showShareFileFolder">&nbsp;Zip Upload </zip-file-uploader>
                            <!--<button type="button" class="btn btn-secondary mr-2" disabled=""><i class="fa fa-cloud-download-alt"></i></button>-->
                            <!-- <div class="btn-group mr-2">
                                <button type="button" class="btn btn-default md-btn-flat dropdown-toggle px-2" data-toggle="dropdown"><i class="fa fa-cog"></i></button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="javascript:void(0)" @click="removeFileFolder()">Remove</a>
                                </div>
                            </div> -->
                        </div>
                        <div>
                            <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                <label class="btn btn-default md-btn-flat active"> <input type="radio" name="file-manager-view" value="file-manager-col-view" checked="" @click="fileManagerView('col')"/> <span class="fa fa-columns"></span> </label>
                                <label class="btn btn-default md-btn-flat"> <input type="radio" name="file-manager-view" value="file-manager-row-view" @click="fileManagerView('row')"/> <span class="fa fa-bars"></span> </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="container-fluid flex-grow-1 light-style" style="background-color: white;">
                        <div class="container-m-nx container-m-ny bg-lightest pt-3 pb-3">
                            <div class="file-manager-container" :class="fileView=='col' ? 'file-manager-col-view' : 'file-manager-row-view'">
                                <div class="file-manager-row-header">
                                    <div class="file-item-name pb-2">Filename</div>
                                    <div class="file-item-size pb-2">File Size</div>
                                    <div class="file-item-changed pb-2">Last Modified</div>
                                </div>

                                <div class="file-item" v-for="folder in folders" :key="folder.idFolders">
                                    <div class="file-item-select-bg bg-primary"></div>
                                    <label class="file-item-checkbox" v-if="fileView=='col'">
                                        <img :src="showStatus(folder.status)" :alt="folder.status" :title="folder.status">
                                    </label>
                                    <div class="file-item-icon text-secondary" @click="next(folder)" v-bind:style="{ background: 'url(' + showExtension('folder') + ')', 'background-size': 'cover' }"></div>
                                    <a @click="next(folder)" href="javascript:void(0)" class="file-item-name">
                                        {{folder.name}}
                                    </a>
                                    <div class="file-item-size">-</div>
                                    <div class="file-item-status" v-if="fileView=='row'">
                                        <img :src="showStatus(folder.status)" :alt="folder.status" :title="folder.status">
                                    </div>
                                    <div class="file-item-changed">{{folder.createdAt | customDateTime}}</div>
                                    <div class="btn-group dropdownbtn">
                                        <button type="button" class="btn btn-default btn-sm rounded-pill icon-btn borderless md-btn-flat hide-arrow dropdown-toggle" data-toggle="dropdown"><i class="fa fa-ellipsis-h"></i></button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" href="javascript:void(0)" @click="shareFolderModel(folder)">Share</a>
                                            <a class="dropdown-item" href="javascript:void(0)" @click="editFolderModal(folder)">Rename</a>
                                            <a class="dropdown-item" href="javascript:void(0)" @click="deleteFolder(folder.idFolders)" v-if="currentUserId===1 || currentUserId==folder.created_by">Remove</a>
                                            <a class="dropdown-item" href="javascript:void(0)" @click="downloadFolder(folder.idFolders)">Download</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="file-item" v-for="folder in sharedFolders" :key="folder.idFolders">
                                    <div class="file-item-select-bg bg-primary"></div>
                                    <label class="file-item-checkbox" v-if="currentUserId!==folder.createdBy">
                                        <span v-if="fileView=='col'"><img :src="showStatus(folder.status)" :alt="folder.status" :title="folder.status"><br/></span>
                                        <i class="fa fa-users pt-2"></i>
                                    </label>
                                    <div class="file-item-icon text-secondary" @click="next(folder, 1)" v-bind:style="{ background: 'url(' + showExtension('folder') + ')', 'background-size': 'cover' }"></div>
                                    <a @click="next(folder, 1)" href="javascript:void(0)" class="file-item-name">
                                        {{folder.name}}
                                    </a>
                                    <div class="file-item-size">-</div>
                                    <div class="file-item-status" v-if="fileView=='row'">
                                        <img :src="showStatus(folder.status)" :alt="folder.status" :title="folder.status">
                                    </div>
                                    <div class="file-item-changed">{{folder.createdAt | customDateTime}}</div>
                                    <div class="btn-group dropdownbtn">
                                        <button type="button" class="btn btn-default btn-sm rounded-pill icon-btn borderless md-btn-flat hide-arrow dropdown-toggle" data-toggle="dropdown"><i class="fa fa-ellipsis-h"></i></button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" href="javascript:void(0)" @click="shareFolderModel(folder)">Share</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="file-item" v-for="(fileItem, index)  in files" :key="'my-file-'+index">
                                    <!-- <div class="file-item-select-bg bg-primary"></div>
                                    <label class="file-item-checkbox custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" v-model="fileItem.isSelected" />
                                        <span class="custom-control-label"></span>
                                    </label> -->
                                    <div class="file-item-icon text-secondary" @click="showFileDetails(fileItem)" v-bind:style="{ background: 'url(' + showExtension(fileItem.fileExtension) + ') no-repeat center center' }"></div>
                                    <a href="javascript:void(0)" @click="showFileDetails(fileItem)" class="file-item-name" :title="fileItem.originalFileName">
                                        {{fileItem.originalFileName | truncate(35, '...')}}
                                    </a>
                                    <div class="file-item-size">{{bytesToSize(fileItem.fileSize)}}</div>
                                    <div class="file-item-status" v-if="fileView=='row'">
                                        <img :src="showStatus('Full')" :alt="'Full'" :title="'Full'">
                                    </div>
                                    <div class="file-item-changed">{{fileItem.createdAt | customDateTime}}</div>
                                    <div class="btn-group dropdownbtn">
                                        <button type="button" class="btn btn-default btn-sm rounded-pill icon-btn borderless md-btn-flat hide-arrow dropdown-toggle" data-toggle="dropdown"><i class="fa fa-ellipsis-h"></i></button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" href="javascript:void(0)" @click="editFileModal(fileItem)">Rename</a>
                                            <a class="dropdown-item" href="javascript:void(0)" @click="removeFile(fileItem.idFiles)" v-if="currentUserId===1 || currentUserId==fileItem.created_by">Remove</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="file-item" v-for="(fileItem, index)  in sharedFiles" :key="'shared-file-'+index">
                                    <label class="file-item-checkbox" v-if="currentUserId!==fileItem.createdBy">
                                        <i class="fa fa-users"></i>
                                    </label>
                                    <div class="file-item-icon text-secondary" @click="showFileDetails(fileItem)" v-bind:style="{ background: 'url(' + showExtension(fileItem.fileExtension) + ') no-repeat center center' }"></div>
                                    <a href="javascript:void(0)" @click="showFileDetails(fileItem)" class="file-item-name" :title="fileItem.originalFileName">
                                        {{fileItem.originalFileName | truncate(35, '...')}}
                                    </a>
                                    <div class="file-item-size">{{bytesToSize(fileItem.fileSize)}}</div>
                                    <div class="file-item-status" v-if="fileView=='row'">
                                        <img :src="showStatus('Full')" :alt="'Full'" :title="'Full'">
                                    </div>
                                    <div class="file-item-changed">{{fileItem.createdAt | customDateTime}}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4" v-if="showFileDetail">
            <file-details :file="fileInfo" :page="'my-files'" @loadFileFolders="loadFileFolders" ref="fileDetailRef"></file-details>
        </div>
    </div>

    <!--Folder Modal -->
    <div class="modal fade" id="addNewFolder" tabindex="-1" role="dialog" aria-labelledby="addNewFolder" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!folderEditMode">New Folder</h5>
                    <h5 class="modal-title" v-show="folderEditMode">Rename Folder</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="folderEditMode ? updateFolder() : createFolder()">
                    <div class="modal-body">
                        <div class="form-group">
                            <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button v-show="folderEditMode" type="submit" class="btn btn-success">Rename</button>
                        <button v-show="!folderEditMode" type="submit" class="btn btn-primary">Create</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
    <!--Edit File Modal -->
    <div class="modal fade" id="editFileModel" tabindex="-1" role="dialog" aria-labelledby="editFileModel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Rename</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="updateFile()">
                    <div class="modal-body">
                        <div class="form-group">
                            <input v-model="fileForm.originalFileName" type="text" name="name" class="form-control" :class="{ 'is-invalid': fileForm.errors.has('originalFileName') }">
                            <has-error :form="fileForm" field="originalFileName"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-success">Rename</button>
                    </div>

                </form>
            </div>
        </div>
    </div>

    <share-folder :folder="folderInfo" :page="'my-files'" :updatedValue="folderInfo.idFolders"></share-folder>

    <!-- <pre>selectedInfo: {{selectedInfo}}</pre>
    <pre>currentId: {{currentId}}</pre>
    <pre>previousId: {{previousId}}</pre> -->
    <!-- <pre>showShareFileFolder : {{ showShareFileFolder }}</pre> -->
</section>
</template>

<script>
import ApiService from "../common/api.service";
import FileUploader  from './FileUploader.vue';
import ZipFileUploader  from './ZipFileUploader.vue';
import FileDetails  from './FileDetails.vue';
import Breadcrumb  from './Breadcrumb.vue';
import ShareFolder from './ShareFolder.vue';
import store from '../store';

export default {
    // props: ['id'],
    components: {FileUploader, FileDetails, Breadcrumb, ShareFolder, ZipFileUploader},
    data() {
        return {
            currentUserId: store.state.userInfo.id,
            folderEditMode: false,
            fileView:(localStorage.getItem("settings")) ? JSON.parse(localStorage.getItem("settings")).fileView : 'col',
            folders: [],
            files: [],
            sharedFolders: [],
            sharedFiles: [],
            breadcrumbs: [],
            // Create a new form instance
            form: new Form({
                idFolders: '',
                parentId: null,
                name: ''
            }),
            selectedInfo:{},
            currentId: null,
            previousId: null,
            search: null,
            fileForm: new Form({
                idFiles: '',
                originalFileName: ''
            }),
            showFileDetail:false,
            fileInfo:{},
            folderInfo:{},
            showShareFileFolder:0,
        }
    },
    filters: {
        truncate: function (text, length, suffix) {
            return text.substring(0, length) + suffix;
        },
        extension: function (value) {
            let extensions = JSON.parse(JSON.parse(localStorage.getItem("settings")).extensions);
            const result = extensions.find( ({ type }) => type === value );
            return result.image;
        },
    },
    methods: {

        removeFileFolder(){
            this.$Progress.start();
            let folderArr = [];
            let fileArr = [];

            this.folders.forEach((item, index) => {
                if(item.isSelected == true){
                    folderArr.push(item.idFolders);
                }
            });
            this.files.forEach((item, index) => {
                if(item.isSelected == true){
                    fileArr.push(item.idFiles);
                }
            });

            if(folderArr.length==0 && fileArr.length===0){
                Swal.fire(
                    'No Content!',
                    'Select at least one folder or file.',
                    'info'
                );

                return false;
            }

            let params = { folders: folderArr, files: fileArr }
            ApiService.setHeader();
            ApiService.post("api/myFilesBatchDelete", params)
                .then(({data}) => {

                    Toast.fire({
                        icon: 'success',
                        title: 'Record has been Deleted'
                    });
                    this.$Progress.finish();
                    //  Fire.$emit('AfterCreate');
                    this.loadFileFolders('', this.showShareFileFolder);

                })
                .catch(({response}) => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                });

        },
        showFileDetails(file){
            this.showFileDetail = true;
            this.fileInfo = file;
            var self = this;
            setTimeout(function() {
                self.$refs.fileDetailRef.getFileDetails();
            }, 300);

        },
        fileManagerView(view){
            const settings = JSON.parse(localStorage.getItem('settings'));
            settings.fileView = view;
            localStorage.setItem('settings', JSON.stringify(settings));
            this.fileView = view;
        },
        bytesToSize(bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            if (bytes === 0) return '-'
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },
        next(folderInfo, shared = 0){
            this.showShareFileFolder = shared;
            this.$Progress.start();
            this.selectedInfo = folderInfo;
            this.form.fill({idFolders: '', parentId: folderInfo.idFolders, name: ''});
            this.currentId = folderInfo.idFolders;
            if(folderInfo.parentId!=null){
                this.previousId = folderInfo.parentId;
            }else{
                this.previousId = null;
            }

            this.loadFileFolders(this.currentId, shared);

            // URL update with query string
            this.$router.push({ path: '/my-files', query: { id_folders: this.currentId, shared:shared } })

            this.$Progress.finish();
        },
        previousInfo(selectedId){
            this.showFileDetail = false;
            this.currentId = selectedId;
            this.previousId = null;
            if(selectedId){
                this.currentDirectoryInfo(selectedId);

                // URL update with query string
                let shared = (this.$route.query?.shared) ? 1 : 0;
                this.$router.push({ path: '/my-files', query: { id_folders: this.currentId, shared:shared } })
            }else{
                this.selectedInfo = {};
                this.currentId = null;

                // URL update
                this.$router.push({ path: '/my-files'})
            }

        },
        loadFileFolders(parentId = '', shared = 0) {
            this.showFileDetail = false;
            let params = { parentId: parentId, shared: shared }
            ApiService.setHeader();
            ApiService.post("api/my-files", params)
                .then(({data}) => {

                    this.folders = data.data.folders;
                    this.files = data.data.files;
                    this.sharedFolders = data.data.sharedFolders;
                    this.sharedFiles = data.data.sharedFiles;
                    this.breadcrumbs = data.data.breadcrumbs;
                })
                .catch(({response}) => {

                });

        },

        currentDirectoryInfo(id){
            ApiService.get("api/folder/"+id)
                .then(({data}) => {

                    this.selectedInfo = data.data;
                })
                .catch(({response}) => {
                    console.log(response);
                });
        },
        updateFolder() {
            this.$Progress.start();
            this.form.put('/api/folder/' + this.form.idFolders)
                .then((response) => {
                    // success
                    $('#addNewFolder').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    //  Fire.$emit('AfterCreate');
                    this.loadFileFolders(this.currentId);
                })
                .catch(() => {
                    this.$Progress.fail();
                });

        },
        editFolderModal(folder) {
            // console.log(folder);
            this.folderEditMode = true;
            this.form.reset();
            //Clear all validation error
            this.form.errors.clear(undefined)
            $('#addNewFolder').modal('show');
            this.form.fill(folder);
        },
        newModal() {
            this.folderEditMode = false;
            if(this.selectedInfo.hasOwnProperty('idFolders')){
                this.form.fill({idFolders: '', parentId: this.selectedInfo.idFolders, name: ''});
            }else{
                this.form.reset();
            }

            $('#addNewFolder').modal('show');
        },
        createFolder() {

            this.form.post('/api/folder')
                .then((response) => {

                    $('#addNewFolder').modal('hide');

                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });

                    this.$Progress.finish();
                    let parentId = (this.selectedInfo.hasOwnProperty('idFolders')) ? this.selectedInfo.idFolders : null;
                    this.loadFileFolders(parentId, this.showShareFileFolder);
                })
                .catch((error) => {
                    console.log(error);
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occurred! Please try again'
                    });
                })

        },
        deleteFolder(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    this.form.delete('api/folder/' + id).then((response) => {
                        if(response.data.success==false){
                            Toast.fire({
                                icon: 'error',
                                title: response.data.message
                            });
                        }else{
                            Swal.fire(
                                'Deleted!',
                                'Folder has been deleted.',
                                'success'
                            );
                            // Fire.$emit('AfterCreate');
                            let parentId = (this.selectedInfo.hasOwnProperty('idFolders')) ? this.selectedInfo.idFolders : null;
                            let shared = (this.showShareFileFolder) ? 1 : 0;
                            this.loadFileFolders(parentId, shared);
                        }

                    }).catch((data) => {
                        Swal.fire("Failed!", 'Some error occured! Please try again', "warning");
                    });

                }
            })
        },
        downloadFolder(id) {
            ApiService.get(`api/folder/download/${id}`)
                .then((response) => {
                    // console.log(response)

                    const link = document.createElement('a');
                    link.href = response.data.data;
                    document.body.appendChild(link);
                    link.click();

                    window.URL.revokeObjectURL(downloadUrl);
                    document.body.removeChild(link);
                })
                .catch(({response}) => {
                    console.log(response);
                });
        },
        removeFile(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    ApiService.delete('api/file/'+id).then((response)=>{
                        if(response.data.success==false){
                            Toast.fire({
                                icon: 'error',
                                title: response.data.message
                            });
                        }else{
                            Swal.fire(
                                'Deleted!',
                                'File has been deleted.',
                                'success'
                            );

                            this.loadFileFolders(this.currentId, this.showShareFileFolder);
                        }

                    }).catch((data)=> {
                        Swal.fire("Failed!", data.message, "warning");
                    });
                }
            })
        },
        editFileModal(file) {
            // console.log(file);
            this.fileForm.reset();
            //Clear all validation error
            this.fileForm.errors.clear(undefined)
            $('#editFileModel').modal('show');
            this.fileForm.fill(file);
        },
        updateFile() {
            this.$Progress.start();
            this.fileForm.put('/api/file/' + this.fileForm.idFiles)
                .then((response) => {
                    // success
                    $('#editFileModel').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadFileFolders(this.currentId, this.showShareFileFolder);
                })
                .catch(() => {
                    this.$Progress.fail();
                });

        },
        //My folder share
        shareFolderModel(folderInfo){
            this.folderInfo = folderInfo;
            // this.shareForm.reset();
            //Clear all validation error
            // this.shareForm.errors.clear(undefined)
            $('#shareFolderModel').modal('show');

        },

    },
    mounted() {
        const self = this; // Capture the 'this' context
        this.$Progress.start();
        if(this.$route.query?.id_folders){
            console.log("mounted = ", this.$route.query);
            let shared = (this.$route.query?.shared) ? 1 : 0;
            let idFolders = Number(this.$route.query?.id_folders);
            this.showShareFileFolder = shared;
            this.currentId = idFolders;
            this.loadFileFolders(idFolders, shared);
            this.$Progress.finish();
        }else{
            console.log("mounted query null = ", this.$route.query);
            this.loadFileFolders();
            this.$Progress.finish();
        }

        // if back button is pressed
        window.onpopstate = function(event) {
            if(self.$route.query?.id_folders){
                let shared = (self.$route.query?.shared) ? 1 : 0;
                let idFolders = Number(self.$route.query?.id_folders);
                self.showShareFileFolder = shared;
                self.currentId = idFolders;
                self.loadFileFolders(idFolders, shared);
                self.$Progress.finish();
            }else{
                self.loadFileFolders();
                self.$Progress.finish();
            }
        };
    },
    created() {
        console.log("created = ", this.$route.query);
        // this.$Progress.start();
        // this.loadFileFolders();
        // this.$Progress.finish();

    },
    computed: {

    },
}
</script>
<style src="./file_manager_style.css"></style>
