<template>
<section class="content">
    <div class="card">
        <div class="card-header">{{file.originalFileName}}</div>
        <div class="card-body p-2">

            <div class="row">
                <div class="col">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="custom-tabs-basicinfo-tab" data-toggle="pill" href="#custom-tabs-basicinfo" role="tab" aria-controls="custom-tabs-basicinfo" aria-selected="false">Basic Info</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="custom-tabs-comments-tab" data-toggle="pill" href="#custom-tabs-comments" role="tab" aria-controls="custom-tabs-comments" aria-selected="true">Comments</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="custom-tabs-activity-tab" data-toggle="pill" href="#custom-tabs-activity" role="tab" aria-controls="custom-tabs-activity" aria-selected="true">Timeline</a>
                        </li>
                    </ul>
                    <div class="tab-content pt-1">
                        <div class="tab-pane fade active show" id="custom-tabs-basicinfo" role="tabpanel" aria-labelledby="custom-tabs-basicinfo-tab">
                            <div class="row">
                                <div class="col-md-12">
                                    <dl>
                                        <dt>Description:</dt>
                                        <dd>{{(file.description) ? file.description : 'N/A'}}</dd>
                                        <dt>Version History:</dt>
                                        <table class="table">
                                            <tbody>
                                                <tr v-for="(item, key) in versionHistories" :key="key">
                                                    <td>{{++key}}</td>
                                                    <td>{{bytesToSize(item.fileSize)}}</td>
                                                    <td>{{item.createdAt | customDateTime}}</td>
                                                    <!-- <td><a :href="AWS_URL+'uploads/'+item.directory+'/'+item.originalFileName" target="_blank"><i class="fa fa-cloud-download-alt"></i>&nbsp;</a></td> -->
                                                    <td><a @click="versionTrackAndDownload(item)" :title="item.originalFileName"><i class="fa fa-cloud-download-alt"></i>&nbsp;</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </dl>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" v-if="page==='my-files'">
                                    <button type="button" class="btn btn-block btn-outline-primary" @click="uploadModal" data-backdrop="static" data-keyboard="false"><i class="fa fa-cloud-upload-alt"></i>&nbsp;Upload Another version</button>
                                </div>
                                <div class="col-md-12 text-center">
                                    <!-- v-if="page!=='trash'" -->
                                    <button type="button" class="btn btn-outline-warning mt-2 float-left" @click="shareFileModel()" v-if="page==='my-files'"><i class="fa fa-share"></i>&nbsp;Share</button>
                                    <a class="btn btn-outline-secondary mt-2 ml-4" @click="trackAndDownload(file)"><i class="fa fa-cloud-download-alt"></i>&nbsp;Download</a>
                                    <!-- <a class="btn btn-outline-secondary mt-2 ml-4" :href="AWS_URL+'uploads/'+file.directory+'/'+file.originalFileName" target="_blank"><i class="fa fa-cloud-download-alt"></i>&nbsp;Download</a> -->
                                    <!-- <button type="button" class="btn btn-outline-danger mt-2 float-right" @click="removeFile(file.idFiles)" v-if="page==='my-files'"><i class="fa fa-trash"></i>&nbsp;Delete</button> -->
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="custom-tabs-comments" role="tabpanel" aria-labelledby="custom-tabs-comments-tab">
                            <div class="row">
                                <div class="col-md-12">
                                    <!-- DIRECT CHAT PRIMARY -->

                                    <div class="direct-chat-messages" v-if="comments.length>0">
                                        <div class="direct-chat-msg" v-for="(comment, key) in comments" :key="key">
                                            <div class="direct-chat-info clearfix">
                                                <span class="direct-chat-name float-left">{{comment.name}}</span>
                                                <span class="direct-chat-timestamp float-right">{{comment.created_at | customDateTime}}</span>
                                            </div>
                                            <img class="direct-chat-img" :src="(comment.photo) ? AWS_URL+'uploads/users/'+comment.photo : 'images/profile.png'" :alt="comment.name">
                                            <div class="direct-chat-text">
                                                {{comment.comment}}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p class="text-danger text-center">No Comment Yet!</p>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <form @submit.prevent="addComment()">
                                        <div class="form-group">
                                            <textarea class="form-control" rows="3" v-model="form.comment" :class="{ 'is-invalid': form.errors.has('comment') }"></textarea>
                                            <has-error :form="form" field="comment"></has-error>
                                        </div>
                                        <div class="form-group">
                                            <button type="submit" class="btn btn-block btn-outline-primary"><i class="fa fa-comments"></i>&nbsp;Comments</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade scrollable-content" id="custom-tabs-activity" role="tabpanel" aria-labelledby="custom-tabs-activity-tab">
                            <div class="container py-2 mt-4 mb-4">

                                <!-- timeline item 3 -->
                                <div class="row" v-for="(timeline, key) in timelines" :key="key">
                                    <div class="col-auto text-center flex-column d-none d-sm-flex p-0">
                                        <div class="row h-50">
                                            <div class="col border-right">&nbsp;</div>
                                            <div class="col">&nbsp;</div>
                                        </div>
                                        <h5 class="m-2">
                                            <span class="badge badge-pill bg-light border" style="width:50px; height:50px;padding:4px;">
                                                <img v-if="timeline.actionType=='File Shared'" class="direct-chat-img" :src="(timeline.user_to!=null) ? AWS_URL+'uploads/users/'+timeline.user_to.photo : 'images/profile.png'">
                                                <img v-else class="direct-chat-img" :src="(timeline.photo!=null) ? AWS_URL+'uploads/users/'+timeline.photo : 'images/profile.png'">
                                            </span>
                                        </h5>
                                        <div class="row h-50">
                                            <div class="col border-right">&nbsp;</div>
                                            <div class="col">&nbsp;</div>
                                        </div>
                                    </div>
                                    <div class="col py-2">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="float-right text-muted"> at {{timeline.actionDate | customDateTime}}</div>
                                                <h4 class="card-title">{{timeline.actionType}}</h4>
                                                <p class="card-text">{{timeline.actionText}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--/row-->

                            </div>
                            <!--container-->
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!--Share Modal -->
    <div class="modal fade" id="shareModel" tabindex="-1" role="dialog" aria-labelledby="shareModel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Share "{{ file.originalFileName }}"</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="shareFile()">
                    <div class="modal-body">
                        <div class="form-group">
                            <v-select style="min-width: 200px;" :options="users" v-model="shareForm.userObj" label="name" placeholder="Select User" multiple>
                                <template v-slot:option="option">
                                    <div class="font-weight-bolder">
                                        {{ option.name }}
                                    </div>
                                    <div class="font-weight-bolder">
                                        <span class="fa fa-envelope icon-state-success"></span>
                                        {{ option.email }}
                                    </div>
                                </template>
                            </v-select>
                            <has-error :form="shareForm" field="userObj"></has-error>
                        </div>
                        <div class="form-group">
                            <label for="my-input">People with access</label>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Access</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="user-panel d-flex">
                                                <div class="circle-img img-circle" v-if="file.creator">
                                                    <img v-if="file.creator.photo===null" :src="AWS_URL+'uploads/users/default.png' " class="img-circle elevation-2" alt="User Image">
                                                    <img v-else :src="AWS_URL+'uploads/users/'+file.creator.photo" class="img-circle elevation-2" alt="User Image">
                                                </div>
                                                <div class="info">
                                                    {{(file.creator) ? file.creator.full_name : ''}}<br/>
                                                    {{(file.creator) ? file.creator.email : ''}}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="badge badge-success">Owner</span>
                                        </td>
                                    </tr>
                                    <tr v-for="(user, index) in sharedUsers" :key="index">
                                        <td>
                                            <div class="user-panel d-flex">
                                                <div class="circle-img img-circle" v-if="user">
                                                    <img v-if="user.photo===null" :src="AWS_URL+'uploads/users/default.png' " class="img-circle elevation-2" alt="User Image">
                                                    <img v-else :src="AWS_URL+'uploads/users/'+user.photo" class="img-circle elevation-2" alt="User Image">
                                                </div>
                                                <div class="info">
                                                    {{(user) ? user.name : ''}}<br/>
                                                    {{(user) ? user.email : ''}}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-sm btn-danger" @click="removeAccess(user.id_file_shares)">
                                                <span class="fa fa-trash"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-success">Done</button>
                    </div>

                </form>
            </div>
        </div>
    </div>

    <!--File Modal -->
    <div class="modal fade" id="addAnotherFile" tabindex="-1" role="dialog" aria-labelledby="addAnotherFile" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Upload Another Version ({{files.length}} file selected)</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <table class="table" v-if="files.length">
                        <thead>
                            <tr>
                                <th style="width: 10px">#</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Size</th>
                                <th>Progress</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody v-for="(item, key) in files" :key="key">

                            <tr>
                                <td>{{++key}}.</td>
                                <td>{{item.file.name}}</td>
                                <td>{{item.file.type}}</td>
                                <td>{{bytesToSize(item.file.size)}}</td>
                                <td>
                                    <div class="progress progress-xs">
                                        <div class="progress-bar progress-bar-danger" :style="'width:'+Number(item.uploadPercentage)+'%'" :aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </td>
                                <td><button type="button" v-on:click="removeRow(key-1)" v-if="!item.isUploaded" class="text-danger"> <i class="fa fa-trash"></i> </button></td>
                            </tr>
                        </tbody>
                    </table>
                    <h3 class="text-center" v-else>You have not chosen any file to upload.</h3>
                </div>
                <div class="modal-footer">
                    <div class="row" style="width:100%;">
                        <div class="col-md-8">
                            <div class="upload-btn-wrapper">
                                <button class="btn"><i class="fa fa-file"></i> Select file</button>
                                <input type="file" name="myfile" @change="handleFileUpload( $event )" />
                            </div>
                        </div>
                        <div class="col-md-4 text-right">
                            <button type="button" class="btn btn-primary" :disabled="submitFile" v-on:click="startFileUpload()"><i class="fa fa-upload"></i> Start Upload</button>
                        </div>
                        <!-- <pre>{{selectedInfo}}</pre>
                        <pre>{{files}}</pre> -->
                    </div>
                </div>

            </div>
        </div>
    </div>

</section>
</template>

<style>
.direct-chat-text {
    border-radius: 5px;
    position: relative;
    padding: 5px 10px;
    background: #d2d6de2b;
    border: 1px solid #d2d6dea3;
    margin: 5px 0 0 50px;
    color: #444;
}

.direct-chat-text:after,
.direct-chat-text:before {
    position: absolute;
    right: 100%;
    top: 15px;
    border: solid transparent;
    border-right-color: #d2d6dea3;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
}

.direct-chat-text:after {
    border-width: 5px;
    margin-top: -5px;
}

.direct-chat-text:before {
    border-width: 6px;
    margin-top: -6px;
}

.direct-chat-img {
    border-radius: 50%;
    float: left;
    width: 40px;
    height: 40px;
}

.direct-chat-info {
    display: block;
    margin-bottom: 2px;
    font-size: 12px;
}

.direct-chat-name {
    font-weight: 600;
}

.direct-chat-timestamp {
    color: #999;
}

.scrollable-content {
    overflow-y: auto;
    max-height: 400px;  /* Adjust this value according to your needs */
}

.scrollable-content::-webkit-scrollbar {
    width: 5px;  /* Adjust the width for vertical scrollbars */
}

.scrollable-content::-webkit-scrollbar-track {
    background: #f1f1f1;  /* The track or background color of the scrollbar */
}

.scrollable-content::-webkit-scrollbar-thumb {
    background: #888;  /* The actual scroll handle or thumb color */
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
    background: #555;  /* The scroll handle color on hover */
}
</style>

<script>
import ApiService from "../common/api.service";
import store from '../store';

export default {
    name: "FileDetails",
    props: ['file', 'page'],
    data() {
        return {
            currentUserId: store.state.userInfo.id,
            comments: [],
            versionHistories: [],
            timelines: [],
            users: [],
            form: new Form({
                idFiles: this.file.idFiles,
                comment: ''
            }),
            shareForm: new Form({
                idFiles: this.file.idFiles,
                userObj: []
            }),
            sharedUsers: this.file.shared,
            files: [],
            submitFile: false
        }
    },

    methods: {
        async trackAndDownload(file) {
            try {
                this.$Progress.start();
                let response = await axios.get('/api/file/track-download/' + file.idFiles);
                if (response.status === 200) {
                    this.getFileDetails();
                    this.$Progress.finish();
                    window.open(this.AWS_URL+'uploads/'+file.directory+'/'+file.originalFileName, '_blank');
                }
            } catch (error) {
                this.$Progress.finish();
                console.error("Error tracking download:", error);
            }
        },
        async versionTrackAndDownload(version) {
            console.log(version, 'version')
            try {
                this.$Progress.start();
                let response = await axios.get('/api/file/track-version-download/' + version.id_version_histories);
                if (response.status === 200) {
                    this.getFileDetails();
                    this.$Progress.finish();
                    window.open(this.AWS_URL+'uploads/'+version.directory+'/'+version.originalFileName, '_blank');
                }
            } catch (error) {
                this.$Progress.finish();
                console.error("Error tracking versoin download:", error);
            }
        },
        bytesToSize(bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            if (bytes === 0) return '0'
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },
        uploadModal() {
            $('#addAnotherFile').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
            })
            this.submitFile = false;
            this.files = [];
        },
        handleFileUpload(event) {
            this.submitFile = false;
            let files = event.target.files;
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                this.files.push({
                    file: file,
                    uploadPercentage: 0,
                    isUploaded: false
                });
            }

        },
        removeRow(index) {
            this.files.splice(index, 1)
        },
        async startFileUpload() {
            if (this.files.length === 0) {
                Toast.fire({
                    icon: 'error',
                    title: 'File must be selected!'
                });
                return false;
            }

            this.$Progress.start();
            this.submitFile = true;

            const self = this;
            var totalUploaded = 0;
            for (var i = 0; i < this.files.length; i++) {
                /*
                    Initialize the form data
                */

                let formData = new FormData();
                let file = this.files[i];
                if (!file.isUploaded) {
                    /*
                        Iteate over any file sent over appending the files
                        to the form data.
                    */
                    formData.append("idFiles", this.file.idFiles);
                    formData.append('file', file.file);

                    await axios.post("api/file/upload-another-version", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            },
                            onUploadProgress: function (progressEvent) {

                                var percentCompleted = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                                self.files[i].uploadPercentage += percentCompleted;

                            }
                        })
                        .then(({
                            data
                        }) => {
                            self.files[i].isUploaded = true;
                            totalUploaded++;
                        })
                        .catch(({
                            error
                        }) => {
                            self.submitFile = false;

                            Toast.fire({
                                icon: "error",
                                title: "Upload Error",
                                text: "Something went worng from our end!"
                            });
                        });

                }

            } //for

            if (totalUploaded === this.files.length) {

                Toast.fire({
                    icon: 'success',
                    title: 'Upload succeeded'
                });
                $('#addAnotherFile').modal('hide');
                this.getFileDetails();
                this.$Progress.finish();
            }

        },
        shareFileModel() {
            this.shareForm.reset();
            //Clear all validation error
            this.shareForm.errors.clear(undefined)
            $('#shareModel').modal('show');
        },
        shareFile() {

            this.shareForm.post('/api/file/share')
                .then((response) => {

                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    $('#shareModel').modal('hide');
                    this.$Progress.finish();
                    this.getFileDetails();
                })
                .catch((error) => {
                    if (error.response && error.response?.status === 422) {
                        const validationErrors = error.response.data.errors;
                        // Display validation errors or specific messages
                        for (const field in validationErrors) {
                            if (validationErrors.hasOwnProperty(field)) {
                                // You can access individual field errors like this:
                                // validationErrors[field]
                            }
                        }
                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.message
                        });

                    } else {
                        console.error(error);
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occurred! Please try again'
                        });
                    }
                })
        },
        getFileDetails() {
            // this.$Progress.start();
            ApiService.get("api/file/" + this.file.idFiles)
                .then(({
                    data
                }) => {
                    this.comments = data.data.comments;
                    this.versionHistories = data.data.version_histories;
                    this.timelines = data.data.timelines;
                    this.shareForm.reset();
                    var self = this;
                    setTimeout(function () {
                        self.sharedUsers = data?.data?.shared;
                        self.shareForm.fill({
                            idFiles: self.file.idFiles,
                            userObj: []
                        });
                    }, 500);
                    // this.$Progress.finish();
                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                });
        },
        removeFile(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    ApiService.delete('api/file/' + id).then(() => {
                        Swal.fire(
                            'Deleted!',
                            'File has been deleted.',
                            'success'
                        );

                        this.loadFileFolders();
                    }).catch((data) => {
                        Swal.fire("Failed!", data.message, "warning");
                    });
                }
            })
        },
        loadFileFolders() {
            this.$emit('loadFileFolders', this.file.idFolders);
        },
        getAllUsers() {
            ApiService.get("api/file/user-list")
                .then(({
                    data
                }) => {
                    this.users = data.data;
                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                });
        },
        addComment() {

            this.form.post('/api/file/comment')
                .then((response) => {

                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });

                    this.$Progress.finish();
                    this.getFileDetails();
                    this.form.reset();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
        },
        removeAccess(idFileShares) {
            this.$Progress.start();
            ApiService.setHeader();
            ApiService.delete("api/shared/remove-access/"+idFileShares)
                .then(({
                    data
                }) => {
                    this.getFileDetails();
                    this.$Progress.finish();
                })
                .catch(({
                    response
                }) => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occurred! Please try again'
                    });
                });
        }
    },
    created() {
        console.log(this.page);
        this.getAllUsers();
    }
}
</script>
