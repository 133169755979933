<template>

    <ol class="breadcrumb text-big" v-if="breadcrumbs.length" style="margin-bottom: 0;">
        <li class="breadcrumb-item normal" @click="getRecords()">Home</li>
        <li class="breadcrumb-item" v-for="(item, key) in breadcrumbs" :key="key" :class="item.id_folders==(currentId || selectedId) ? 'active' : 'normal'" @click="getRecords(item.id_folders)">
            {{item.name}}
        </li>
    </ol>

</template>
<style>
    .breadcrumb-item.normal{
        color: #3490dc;
        text-decoration: none;
        background-color: transparent;
    }

    .breadcrumb-item.normal:hover {
        color: #1d68a7;
        text-decoration: underline;
        cursor: pointer;
    }

</style>

<script>
import ApiService from "../common/api.service";

export default {
    name: "breadcrumb",
    props: ['breadcrumbs', 'currentId', 'showShareFileFolder'],
    data() {
        return {
            selectedId:null
        }
    },
    methods: {
        getRecords(id = null) {
            this.selectedId = id;
            this.$Progress.start();
            //Call parent component function
			this.$emit('previousInfo', id);
			this.$emit('loadFileFolders', id, this.showShareFileFolder);
            this.$Progress.finish();
		}
    },
    created() {

    }
}
</script>
