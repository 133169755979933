<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">

            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header">
                <h3 class="card-title">Designation List</h3>

                <div class="card-tools">

                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="designation in designations" :key="designation.id_designations">

                      <td>{{designation.id_designations}}</td>
                      <td class="text-capitalize">{{designation.name}}</td>
                      <td>{{designation.description}}</td>
                      <td>{{designation.created_at | myDate}}</td>
                      <td>
                        <a href="#" @click="editModal(designation)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteDesignation(designation.id_designations)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">

              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Designation</h5>
                    <h5 class="modal-title" v-show="editmode">Update Designation</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateDesignation() : createDesignation()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <input v-model="form.description" type="text" name="description"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                            <has-error :form="form" field="description"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>


<script>
    import ApiService from "../common/api.service";
    export default {
        data () {
            return {
                editmode: false,
                designations : [],
                // Create a new form instance
                form: new Form({
                    id_designations : '',
                    name: '',
                    description: '',
                })
            }
        },
        methods: {

            loadDesignations(){
                ApiService.setHeader();
                ApiService.get("api/designation").then(({ data }) => (this.designations = data.data));
            },
            updateDesignation(){
                this.$Progress.start();
                this.form.put('/api/designation/'+this.form.id_designations)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadDesignations();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(designation){
                this.editmode = true;
                this.form.reset();
                //Clear all validation error
                this.form.errors.clear(undefined)
                $('#addNew').modal('show');
                this.form.fill(designation);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            createDesignation(){

                this.form.post('/api/designation')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadDesignations();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            deleteDesignation(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                            this.form.delete('api/designation/'+id).then(()=>{
                                Swal.fire(
                                    'Deleted!',
                                    'Designation has been deleted.',
                                    'success'
                                );
                                // Fire.$emit('AfterCreate');
                                this.loadDesignations();
                            }).catch((data)=> {
                                Swal.fire("Failed!", data.message, "warning");
                            });
                         }
                    })
            },

        },
        mounted() {

        },
        created() {
            this.$Progress.start();

            this.loadDesignations();

            this.$Progress.finish();
        }
    }
</script>
