<style>
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.upload-btn-wrapper .btn {
  border: 2px solid #ced4da;
  color: gray;
  background-color: white;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

</style>

<template>
    <div>
        <button type="button" class="btn btn-primary mr-2" @click="uploadModal" data-backdrop="static" data-keyboard="false"><i class="fa fa-cloud-upload-alt"></i>&nbsp; Upload</button>

        <!--File Modal -->
        <div class="modal fade" id="addNewFile" tabindex="-1" role="dialog" aria-labelledby="addNewFile" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Upload Files ({{files.length}} files selected)</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body py-0 px-1">
                        <table class="table table-sm" v-if="files.length">
                            <thead>
                                <tr>
                                    <th style="width: 10px">#</th>
                                    <th>Name</th>
                                    <th>Size</th>
                                    <th>Progress</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody v-for="(item, key) in files" :key="key">

                                <tr>
                                    <td>{{++key}}.</td>
                                    <td>{{item.file.name}}</td>
                                    <td>{{bytesToSize(item.file.size)}}</td>
                                    <td>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar progress-bar-danger" :style="'width:'+Number(item.uploadPercentage)+'%'" :aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </td>
                                    <td>{{capitalizeFirstLetter(item.status)}}</td>
                                    <td>
                                        <button type="button" v-on:click="removeRow(key-1)" v-if="item.status!='In Progress'" class="btn btn-outline-danger"> <i class="fa fa-trash"></i> </button>
                                    </td>
                                </tr>
                                <!-- <tr>
                                    <td></td>
                                    <td colspan="6"><textarea class="form-control" rows="3" v-model="item.description" placeholder="Enter ..."></textarea></td>
                                </tr> -->

                            </tbody>
                        </table>
                        <h3 class="text-center" v-else>You have not chosen any files to upload.</h3>
                    </div>
                    <div class="modal-footer">
                        <div class="row" style="width:100%;">
                            <div class="col-md-6">
                                <div class="upload-btn-wrapper">
                                    <button type="button" class="btn btn-default" :disabled="submitFile"><i class="fa fa-file"></i> Select more files</button>
                                    <input type="file" name="myfile" multiple @change="handleFileUpload( $event )" :disabled="submitFile"/>
                                </div>
                            </div>
                            <div class="col-md-6 text-right">
                                <button v-if="files.length" type="button" class="btn btn-danger" v-on:click="cancelRequest()"><i class="fa fa-window-close"></i> Cancel Request</button>
                                <button v-if="files.length" type="button" class="btn btn-primary" :disabled="submitFile" v-on:click="startFileUpload()"><i class="fa fa-upload"></i> Start Upload</button>
                            </div>
                            <!-- <pre>{{selectedInfo}}</pre>
                            <pre>{{files}}</pre> -->
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ApiService from "../common/api.service";
import * as msg from "../utils/request-messages";
export default {
    props: ['selectedInfo', 'loadFileFolders', 'showShareFileFolder'],
    // components: {},
    data() {
        return {
            // Create a new file form instance
            files:[],
            submitFile:false,
            request: null,
        }
    },
    methods: {
        bytesToSize(bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            if (bytes === 0) return '0'
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },
        uploadModal(){
            $('#addNewFile').modal({
                backdrop: 'static',
                keyboard: false,
                show:true
            })
            this.submitFile = false;
            this.files = [];

        },
        handleFileUpload( event ){
            this.submitFile = false;
            let files = event.target.files;
            for( var i = 0; i < files.length; i++ ){
                let file = files[i];
                this.files.push({description: '', file:file, uploadPercentage:0, isUploaded:false, status:msg.LOADING});
            }

        },
        removeRow(index){
            this.files.splice(index, 1);
            this.request = null;
        },
        async startFileUpload(){

            // if (this.request) this.cancel();

            if(this.files.length===0){
                Toast.fire({
                    icon: 'error',
                    title: 'File must be selected!'
                });
                return false;
            }

            this.$Progress.start();
            this.submitFile = true;
            let idFolders = (this.selectedInfo.hasOwnProperty('idFolders')) ? this.selectedInfo.idFolders : '';
            this.cancel();
            let axiosSource = axios.CancelToken.source();
            this.request = { cancel: axiosSource.cancel };

            const self = this;
            var totalUploaded = 0;
            for (var i = 0; i < this.files.length; i++) {
                /*
                    Initialize the form data
                */

                let formData = new FormData();
                let file = this.files[i];
                if(!file.isUploaded){
                    // console.log("isUploaded = ", file.isUploaded);
                    /*
                        Iteate over any file sent over appending the files
                        to the form data.
                    */
                    formData.append("idFolders", idFolders);
                    formData.append('description', file.description);
                    formData.append('file', file.file);
                    self.files[i].status = msg.INPROGRESS

                    await axios.post("api/file-upload", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        },
                        cancelToken: axiosSource.token,
                        onUploadProgress: function( progressEvent ) {
                            // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                            var percentCompleted = parseInt(Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );

                            self.files[i].uploadPercentage += percentCompleted;

                        }
                    })
                    .then(( data ) => {
                        console.log(data);
                        self.files[i].isUploaded = true;
                        self.files[i].status = msg.SUCCESS
                        totalUploaded++;
                    })
                    .catch((err) => {
                        this.submitFile = false;
                        if (axios.isCancel(err)) {
                            self.files[i].status = msg.CANCELLED
                            Toast.fire({
                                icon: "error",
                                title: "Request cancelled",
                                text: "File request has been cancelled!"
                            });
                        }else{
                            self.files[i].status = msg.REQ_FAILED
                            Toast.fire({
                                icon: "error",
                                title: "Upload Error",
                                text: "Something went worng from our end!"
                            });
                        }
                    });

                }

            }//for


            if(totalUploaded===this.files.length){

                Toast.fire({
                    icon: 'success',
                    title: 'Upload succeeded'
                });
                $('#addNewFile').modal('hide');
                this.$emit('loadFileFolders', idFolders, this.showShareFileFolder);
                this.$Progress.finish();
            }

        },

        cancelRequest() {

            // const controller = new AbortController();
            // controller.abort();
            this.cancel();

            this.files = this.files.map(function(element){
                if(element.isUploaded==false){
                    element.uploadPercentage = 0;
                }
                return element;
            });

            // console.log(this.files);

        },
        cancel() {
            if (this.request) this.request.cancel();
        },
    },
    mounted() {
        console.log("mounted");
    },
    created() {

    }
}
</script>
