<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row ">
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon bg-info elevation-1"
                            ><i class="fas fa-file"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Total Files</span>
                            <span class="info-box-number">{{
                                totalFiles
                            }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-warning elevation-1"
                            ><i class="fas fa-file"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">This Year</span>
                            <span class="info-box-number">{{
                                thisYearCountFiles
                            }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-danger elevation-1"
                            ><i class="fas fa-file"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">This Month</span>
                            <span class="info-box-number">{{
                                thisMonthCount
                            }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-success elevation-1"
                            ><i class="fas fa-file"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Today</span>
                            <span class="info-box-number">{{
                                todayFileCount
                            }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
            <div class="row">
                <div class="col">
                    <div class="table-responsive">
                        <vue-good-table
                            mode="remote"
                            title="Timelines"
                            styleClass="vgt-table bordered vgt-table-custom"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRecords"
                            :columns="columns"
                            :rows="rows"
                            :isLoading.sync="isLoading"
                            :search-options="{
                                enabled: true,
                                trigger: 'enter',
                                skipDiacritics: true,
                                placeholder: 'Search this table'
                            }"
                            @on-search="onSearch"
                            :pagination-options="{
                                enabled: true,
                                mode: 'records'
                            }"
                            :sort-options="{
                                enabled: true,
                                initialSortBy: {
                                    field: 'idActionTimelines',
                                    type: 'ASC'
                                }
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <span
                                    v-if="props.column.field == 'actions'"
                                >
                                    <router-link :to="'/my-files?id_folders=' + props.row.idFolders" v-if="props.row.idFolders">
                                        <i class="fa fa-info-circle"></i>
                                    </router-link>
                                    <router-link :to="'/my-files?id_folders=' + props.row.fileIdFolders" v-else-if="props.row.idFiles && props.row.fileIdFolders">
                                        <i class="fa fa-info-circle"></i>
                                    </router-link>
                                    <router-link :to="'/my-files'" v-else>
                                        <i class="fa fa-info-circle"></i>
                                    </router-link>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
import ApiService from "../common/api.service";
import { mapActions } from "vuex";
import _ from "lodash"; // Import lodash
import flatPickr from "flatpickr";

import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";

export default {
    data() {
        return {
            thisMonthCount: 0,
            thisYearCountFiles: 0,
            todayFileCount: 0,
            totalFiles: 0,
            isLoading: false,
            columns: [
                {
                    label: "ID",
                    field: "idActionTimelines"
                },
                {
                    label: "User",
                    field: "userName",
                    filterOptions: {
                        enabled: true,
                        filterValue: "",
                        trigger: "keyup"
                    }
                },
                {
                    label: "File/Folder",
                    field: "fileName",
                    filterOptions: {
                        enabled: true,
                        filterValue: "",
                        trigger: "keyup"
                    }
                },
                {
                    label: "Action Text",
                    field: "actionText",
                    filterOptions: {
                        enabled: true,
                        filterValue: "",
                        trigger: "keyup"
                    }
                },
                {
                    label: "Action",
                    field: "actions",
                    tdClass: "vue-good-table-col-200 text-center",
                    width: "100px",
                    sortable: false
                }

            ],
            rows: [],
            totalRecords: 0,
            serverParams: {
                columnFilters: {},
                sort: {
                    field: "idActionTimelines",
                    type: "asc"
                },
                page: 1,
                perPage: 10
            }
        };
    },
    methods: {
        ...mapActions(["getTimelines"]),
        filterFn: function(data, filterString) {
            var x = parseInt(filterString);
            return data >= x - 5 && data <= x + 5;
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            // console.log(this.serverParams);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.getTimelines();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.getTimelines();
        },
        onSortChange(params) {
            this.updateParams({
                sort: [
                    {
                        type: params[0].type,
                        field: params[0].field
                    }
                ]
            });
            this.getTimelines();
        },
        onColumnFilter(params) {
            this.updateParams(params);
            this.getTimelines();
        },
        // Implement the debounced search
        onSearch: _.debounce(function (params) {
            this.updateParams(params);
            this.getTimelines();
            return false;
        }, 500), // Adjust the debounce delay as needed
        getTimelines() {
            ApiService.setHeader();
            ApiService.post("api/dashboard/timelines", this.serverParams)
                .then(({ data }) => {
                    this.totalRecords = data.data.totalRecords;
                    this.rows = data.data.rows;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        getDashboardInfo() {
            ApiService.setHeader();
            ApiService.get("api/dashboard")
                .then(({ data }) => {
                    this.thisMonthCount = data.data.thisMonthCount;
                    this.thisYearCountFiles = data.data.thisYearCountFiles;
                    this.todayFileCount = data.data.todayFileCount;
                    this.totalFiles = data.data.totalFiles;

                    //Update localStorage data
                    const settings = JSON.parse(
                        localStorage.getItem("settings")
                    );
                    window.localStorage.setItem(
                        "settings",
                        JSON.stringify({
                            fileView: settings.fileView,
                            extensions: JSON.stringify(data.data.extensions)
                        })
                    );
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        dateRangeFilter(data, filterString) {
            let dateRange = filterString.split("to");
            let startDate = Date.parse(dateRange[0]);
            let endDate = Date.parse(dateRange[1]);
            return (data =
                Date.parse(data) >= startDate && Date.parse(data) <= endDate);
        },
    },
    mounted() {
        let inputs = [
            'input[placeholder="Filter Action Date"]',
        ];

        inputs.forEach(function(input) {
            flatPickr(input, {
                dateFormat: "Y-m-d",
                mode: "range",
                allowInput: true
            });
        });
    },
    created() {
        this.$Progress.start();
        this.getDashboardInfo();
        this.$Progress.finish();
    }
};
</script>
