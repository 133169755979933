<template>
<section class="content">
    <div class="row">
        <div class="col-8" :class="{'col-md-12':!showFileDetail}">
            <breadcrumb :breadcrumbs="breadcrumbs" :currentId="currentId" @loadFileFolders="loadFileFolders" @previousInfo="previousInfo"></breadcrumb>

            <div class="card">
                <div class="card-header">
                    <div class="file-manager-actions container-p-x float-right">
                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                            <label class="btn btn-default md-btn-flat active"> <input type="radio" name="file-manager-view" value="file-manager-col-view" checked="" @click="fileManagerView('col')"/> <span class="fa fa-columns"></span> </label>
                            <label class="btn btn-default md-btn-flat"> <input type="radio" name="file-manager-view" value="file-manager-row-view" @click="fileManagerView('row')"/> <span class="fa fa-bars"></span> </label>
                        </div>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="row">
                        <div class="col-12">
                            <div class="container-fluid flex-grow-1 light-style" style="background-color: white;">
                                <div class="container-m-nx container-m-ny bg-lightest pt-3 pb-3">
                                    <div class="file-manager-container" :class="fileView=='col' ? 'file-manager-col-view' : 'file-manager-row-view'">
                                        <div class="file-manager-row-header">
                                            <div class="file-item-name pb-2">Filename</div>
                                            <div class="file-item-size pb-2">File Size</div>
                                            <div class="file-item-changed pb-2">Last Modified</div>
                                        </div>

                                        <div class="file-item" v-for="folder in folders" :key="folder.idFolders">
                                            <div class="file-item-select-bg bg-primary"></div>
                                            <label class="file-item-checkbox" v-if="fileView=='col'">
                                                <img :src="showStatus(folder.status)" :alt="folder.status" :title="folder.status">
                                            </label>
                                            <div class="file-item-icon text-secondary" @click="next(folder)" v-bind:style="{ background: 'url(' + showExtension('folder') + ')', 'background-size': 'cover' }"></div>
                                            <a @click="next(folder)" href="javascript:void(0)" class="file-item-name">
                                                {{folder.name}}
                                            </a>
                                            <div class="file-item-size">-</div>
                                            <div class="file-item-status" v-if="fileView=='row'">
                                                <img :src="showStatus(folder.status)" :alt="folder.status" :title="folder.status">
                                            </div>
                                            <div class="file-item-changed">{{folder.createdAt | customDateTime}}</div>
                                        </div>

                                        <div class="file-item" v-for="(file, index)  in files" :key="'file-'+index">
                                            <div class="file-item-select-bg bg-primary"></div>
                                            <div class="file-item-icon text-secondary" @click="showFileDetails(file)" v-bind:style="{ background: 'url(' + showExtension(file.fileExtension) + ')',  'background-size': 'cover' }"></div>
                                            <a @click="showFileDetails(file)" href="javascript:void(0)" class="file-item-name" :title="file.originalFileName">
                                                {{file.originalFileName | truncate(35, '...')}}
                                            </a>
                                            <div class="file-item-size">{{bytesToSize(file.fileSize)}}</div>
                                            <div class="file-item-status" v-if="fileView=='row'">
                                                <img :src="showStatus('Full')" :alt="'Full'" :title="'Full'">
                                            </div>
                                            <div class="file-item-changed">{{file.createdAt|customDateTime}}</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4" v-if="showFileDetail">
            <file-details :file="fileInfo" :page="'shared-with-me'" ref="fileDetailRef"></file-details>
        </div>
    </div>
</section>
</template>

<script>
import ApiService from "../common/api.service";
import FileDetails  from './FileDetails.vue';
import Breadcrumb  from './Breadcrumb.vue';


export default {
    components: {FileDetails, Breadcrumb},
    data() {
        return {
            fileView:(localStorage.getItem("settings")) ? JSON.parse(localStorage.getItem("settings")).fileView : 'col',
            folders: [],
            files: [],
            breadcrumbs: [],
            selectedInfo:{},
            currentId: null,
            previousId: null,
            search: null,
            showFileDetail:false,
            fileInfo:{}
        }
    },
    filters: {
        truncate: function (text, length, suffix) {
            return text.substring(0, length) + suffix;
        },
        extension: function (value) {
            let extensions = JSON.parse(JSON.parse(localStorage.getItem("settings")).extensions);
            const result = extensions.find( ({ type }) => type === value );
            return result.image;
        },
    },
    methods: {

        showFileDetails(file){
            this.showFileDetail = true;
            this.fileInfo = file;
            var self = this;
            setTimeout(function() {
                self.$refs.fileDetailRef.getFileDetails();
            }, 300);

        },
        fileManagerView(view){
            const settings = JSON.parse(localStorage.getItem('settings'));
            settings.fileView = view;
            localStorage.setItem('settings', JSON.stringify(settings));
            this.fileView = view;
        },
        bytesToSize(bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            if (bytes === 0) return '-'
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },

        loadFileFolders(parentId = '') {
            this.showFileDetail = false;
            let params = { parentId: parentId }
            ApiService.setHeader();
            ApiService.post("api/shared-with-me", params)
                .then(({data}) => {
                    this.folders = data.data.folders;
                    this.files = data.data.files;
                    this.breadcrumbs = data.data.breadcrumbs;
                    this.$Progress.finish();
                })
                .catch(({response}) => {

                });

        },
        next(folderInfo){
            this.$Progress.start();
            this.selectedInfo = folderInfo;
            this.currentId = folderInfo.idFolders;
            if(folderInfo.parentId!=null){
                this.previousId = folderInfo.parentId;
            }else{
                this.previousId = null;
            }

            this.loadFileFolders(this.currentId);

            // URL update with query string
            this.$router.push({ path: '/shared-with-me', query: { id_folders: this.currentId } })

            this.$Progress.finish();
        },
        previousInfo(selectedId){
            this.showFileDetail = false;
            this.currentId = selectedId;
            this.previousId = null;
            if(selectedId){
                this.currentDirectoryInfo(selectedId);

                // URL update with query string
                this.$router.push({ path: '/shared-with-me', query: { id_folders: this.currentId } })

            }else{
                this.selectedInfo = {};
                this.currentId = null;

                // URL update with query string
                this.$router.push({ path: '/shared-with-me' })
            }

        },
        currentDirectoryInfo(id){
            ApiService.get("api/folder/"+id)
                .then(({data}) => {

                    this.selectedInfo = data.data;
                })
                .catch(({response}) => {
                    console.log(response);
                });
        },

    },
    created() {
        this.$Progress.start();
        if(this.$route.query?.id_folders){
            let idFolders = Number(this.$route.query?.id_folders);
            this.currentId = idFolders;
            this.loadFileFolders(idFolders);
        }else{
            this.loadFileFolders();
            this.$Progress.finish();
        }

    },
    mounted() {
        const self = this; // Capture the 'this' context
        // if back button is pressed
        window.onpopstate = function(event) {
            if(self.$route.query?.id_folders){
                self.$Progress.start();

                let idFolders = Number(self.$route.query?.id_folders);
                self.currentId = idFolders;
                self.loadFileFolders(idFolders);
                self.$Progress.finish();
            }else{
                self.$Progress.start();
                self.loadFileFolders();
                self.$Progress.finish();
            }
        };
    },
}
</script>
<style src="./file_manager_style.css"></style>
