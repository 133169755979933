<template>
<div class="wrapper" v-if="isLoggedIn">
    <!-- Navbar -->
    <navbar></navbar>
    <!-- /.navbar -->

    <!-- Main Sidebar Container -->
    <main-sidebar></main-sidebar>

    <div class="content-wrapper">

        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <router-view></router-view>

        <vue-progress-bar></vue-progress-bar>

    </div>

    <footer class="main-footer">
        <strong>Copyright &copy; 2023 <a href="http://www.destreza.io">Max Group</a>.</strong> All rights reserved.
    </footer>
</div>
<div v-else class="login-box">
    <!-- Main content -->
    <router-view></router-view>

    <vue-progress-bar></vue-progress-bar>
</div>
</template>

<script>
import Navbar  from './components/template/Navbar.vue';
import MainSidebar  from './components/template/MainSidebar.vue';
import store from './store'
export default {
    components: {Navbar, MainSidebar},
    data() {
        return {
            isLoggedIn:store.state.isLoggedIn
        }
    },
    methods: {},
    mounted() {

    },
    created() {

    }
}
</script>
