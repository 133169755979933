<template>
    <section class="content">

        <div class="row">
            <div class="col-8" :class="{ 'col-md-12': !showFileDetail }">
                <breadcrumb :breadcrumbs="breadcrumbs" :currentId="currentId" @loadFileFolders="loadFileFolders"
                    @previousInfo="previousInfo"></breadcrumb>

                <div class="row">
                    <div class="col-12">
                        <div class="file-manager-actions container-p-x py-2 px-3">
                            <div>
                                <h4>Search Results</h4>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="container-fluid flex-grow-1 light-style" style="background-color: white;">
                            <table class="table table-hover table-sm">
                                <thead class="thead-dark">
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>File name</th>
                                        <th>Directory</th>
                                        <th>Create date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="folder in folders" :key="folder.idFolders">
                                        <td><i v-if="folder.isShared == '1'" class="fa fa-users pt-2"></i></td>
                                        <td>
                                            <router-link :to="'/my-files?id_folders=' + folder.idFolders+'&shared='+folder.isShared">
                                                <img v-bind:src="showExtension('folder')" width="25px" alt="">
                                            </router-link>
                                        </td>
                                        <td>
                                            <router-link :to="'/my-files?id_folders=' + folder.idFolders+'&shared='+folder.isShared">
                                                {{ folder.name }}
                                            </router-link>
                                        </td>
                                        <td> {{ folder.parentDirectory }}</td>
                                        <td>{{ folder.createdAt | customDateFormate }}</td>
                                    </tr>
                                    <tr v-for="(file, index)  in files" :key="'file-' + index">
                                        <td><i v-if="file.isShared == '1'" class="fa fa-users pt-2"></i></td>
                                        <td >
                                            <img v-bind:src="showExtension(file.fileExtension)" width="25px" alt="">
                                        </td>
                                        <td>
                                            <a @click="showFileDetails(file)" href="javascript:void(0)">
                                                {{ file.originalFileName }}
                                            </a>
                                        </td>
                                        <td> {{ file.parentDirectory }}</td>
                                        <td>{{ file.createdAt | customDateFormate }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4" v-if="showFileDetail">
                <file-details :file="fileInfo" :page="'trash'" @loadFileFolders="loadFileFolders"
                    ref="fileDetailRef"></file-details>
            </div>
        </div>
    </section>
</template>

<script>
import ApiService from "../common/api.service";
import FileDetails from './FileDetails.vue';
import Breadcrumb from './Breadcrumb.vue';
export default {
    // props: [],
    components: { FileDetails, Breadcrumb },
    data() {
        return {
            fileView: (localStorage.getItem("settings")) ? JSON.parse(localStorage.getItem("settings")).fileView : 'col',
            folders: [],
            files: [],
            breadcrumbs: [],
            selectedInfo: {},
            currentId: null,
            previousId: null,
            search: this.$route.query.q,
            showFileDetail: false,
            fileInfo: {}
        }
    },
    filters: {
        truncate: function (text, length, suffix) {
            return text.substring(0, length) + suffix;
        },
    },
    methods: {
        showFileDetails(file) {
            this.showFileDetail = true;
            this.fileInfo = file;
            var self = this;
            setTimeout(function () {
                self.$refs.fileDetailRef.getFileDetails();
            }, 300);

        },
        fileManagerView(view) {
            const settings = JSON.parse(localStorage.getItem('settings'));
            settings.fileView = view;
            localStorage.setItem('settings', JSON.stringify(settings));
            this.fileView = view;
        },
        bytesToSize(bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            if (bytes === 0) return '-'
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },
        next(folderInfo) {
            console.log(folderInfo);
            this.$Progress.start();
            this.selectedInfo = folderInfo;
            this.currentId = folderInfo.idFolders;
            if (folderInfo.parentId != null) {
                this.previousId = folderInfo.parentId;
            } else {
                this.previousId = null;
            }

            this.loadFileFolders(this.currentId);

            this.$Progress.finish();
        },
        previousInfo(selectedId) {
            this.showFileDetail = false;
            this.currentId = selectedId;
            this.previousId = null;
            if (selectedId) {
                this.currentDirectoryInfo(selectedId);
            } else {
                this.selectedInfo = {};
                this.currentId = null;
            }
        },
        loadFileFolders(parentId = '') {
            this.showFileDetail = false;
            let params = { parentId: parentId, searchText: this.search }
            ApiService.setHeader();
            ApiService.post("api/search", params)
                .then(({ data }) => {

                    this.folders = data.data.folders;
                    this.files = data.data.files;
                    this.breadcrumbs = data.data.breadcrumbs;
                })
                .catch(({ response }) => {

                });

        },

        currentDirectoryInfo(id) {
            ApiService.get("api/folder/" + id)
                .then(({ data }) => {

                    this.selectedInfo = data.data;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },

        deleteFolder(id) {
            let self = this;
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to restore it.",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Delete forever'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    ApiService.delete('api/trash/folder/' + id).then(() => {
                        Swal.fire(
                            'Deleted!',
                            'Folder has been deleted forever.',
                            'success'
                        );

                        let parentId = (self.selectedInfo.hasOwnProperty('idFolders')) ? self.selectedInfo.idFolders : null;
                        self.loadFileFolders(parentId);
                    }).catch((data) => {
                        Swal.fire("Failed!", 'Some error occured! Please try again', "warning");
                    });

                }
            })
        },
        removeFile(id) {
            let self = this;
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to restore it.",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Delete forever'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    ApiService.delete('api/trash/file/' + id).then(() => {
                        Swal.fire(
                            'Deleted!',
                            'File has been deleted forever.',
                            'success'
                        );

                        self.loadFileFolders(self.currentId);
                    }).catch((data) => {
                        Swal.fire("Failed!", data.message, "warning");
                    });
                }
            })
        },

        restore(id, type) {
            let params = { id: id, type: type }
            ApiService.post("api/restore", params)
                .then(({ data }) => {
                    let name = (type === 'folders') ? data.data.name : data.data.file_name;
                    Swal.fire(
                        'Restored!',
                        'Restored ' + name,
                        'success'
                    );

                    let parentId = (this.selectedInfo.hasOwnProperty('idFolders')) ? this.selectedInfo.idFolders : null;
                    this.loadFileFolders(parentId);
                })
                .catch(({ response }) => {
                    Swal.fire("Failed!", 'Some error occured! Please try again', "warning");
                });


        },
    },
    mounted() {
        console.log("mounted", this.$route.params.q);
    },
    created() {
        this.$Progress.start();
        this.loadFileFolders();
        this.$Progress.finish();
    }
}
</script>
<style src="./file_manager_style.css"></style>
