<template>
    <!--Share Modal -->
    <div class="modal fade" id="shareFolderModel" tabindex="-1" role="dialog" aria-labelledby="shareFolderModel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Share {{ folder.name }} with people</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="submitShareFolder()">
                    <div class="modal-body">
                        <div class="form-group">
                            <v-select style="min-width: 200px;" :options="users" v-model="shareFolderForm.userObj" label="name" placeholder="Select User" multiple>
                                <template v-slot:option="option">
                                    <div class="font-weight-bolder">
                                        {{ option.name }}
                                    </div>
                                    <div class="font-weight-bolder">
                                        <span class="fa fa-envelope icon-state-success"></span>
                                        {{ option.email }}
                                    </div>
                                </template>
                            </v-select>
                            <has-error :form="shareFolderForm" field="userObj"></has-error>
                            <has-error :form="shareFolderForm" field="idFolders"></has-error>
                        </div>
                        <div class="form-group">
                            <label for="my-input">People with access</label>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Access</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="user-panel d-flex">
                                                <div class="circle-img img-circle" v-if="folder.creator">
                                                    <img v-if="folder.creator.photo===null" :src="AWS_URL+'uploads/users/default.png' " class="img-circle elevation-2" alt="User Image">
                                                    <img v-else :src="AWS_URL+'uploads/users/'+folder.creator.photo" class="img-circle elevation-2" alt="User Image">
                                                </div>
                                                <div class="info">
                                                    {{(folder.creator) ? folder.creator.full_name : ''}}<br/>
                                                    {{(folder.creator) ? folder.creator.email : ''}}
                                                </div>
                                            </div>

                                        </td>
                                        <td>
                                            <span class="badge badge-success">Owner</span>
                                        </td>
                                    </tr>
                                    <tr v-for="(user, index) in sharedUsers" :key="index">
                                        <td>
                                            <div class="user-panel d-flex">
                                                <div class="circle-img img-circle" v-if="user">
                                                    <img v-if="user.photo===null" :src="AWS_URL+'uploads/users/default.png' " class="img-circle elevation-2" alt="User Image">
                                                    <img v-else :src="AWS_URL+'uploads/users/'+user.photo" class="img-circle elevation-2" alt="User Image">
                                                </div>
                                                <div class="info">
                                                    {{(user) ? user.name : ''}}<br/>
                                                    {{(user) ? user.email : ''}}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-sm btn-danger" @click="removeAccess(user.id_file_shares)">
                                                <span class="fa fa-trash"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-success">Done</button>
                    </div>
                    <!-- <pre>{{ shareFolderForm }}</pre> -->
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "../common/api.service";

export default {
    name: "FolderDetails",
    props: ['folder', 'page', 'updatedValue'],
    immediate: true,
    data() {
        return {
            users: [],
            shareFolderForm: new Form({
                idFolders: this.folder.idFolders ?? '',
                userObj: []
            }),
            sharedUsers: [],
            submitFolder: false
        }
    },
    watch: {
        updatedValue: function(newVal, oldVal) { // watch it
            this.$Progress.start();
            this.getSharedUsers(newVal);// newVal is the updated idFolders
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            this.shareFolderForm.reset();
            //Clear all validation error
            this.shareFolderForm.errors.clear(undefined)
            //Call the API to get the updated list of shared users
            this.getAllUsers();
            this.$Progress.finish();
        }
    },
    methods: {
        submitShareFolder() {
            this.shareFolderForm.fill({
                idFolders: this.folder.idFolders,
                userObj: this.shareFolderForm.userObj
            });

            this.shareFolderForm.post('/api/folder/share')
                .then((response) => {

                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });

                    this.getSharedUsers(this.folder.idFolders);// newVal is the updated idFolders
                    $('#shareFolderModel').modal('hide');
                    this.$Progress.finish();
                    // this.loadFileFolders();
                })
                .catch((error) => {
                    if (error.response && error.response?.status === 422) {
                        const validationErrors = error.response.data.errors;
                        // Display validation errors or specific messages
                        for (const field in validationErrors) {
                            if (validationErrors.hasOwnProperty(field)) {
                                // You can access individual field errors like this:
                                // validationErrors[field]
                            }
                        }
                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.message
                        });

                    }else if(error.response && error.response?.status === 400){
                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.message
                        });
                    } else {
                        console.error(error);
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occurred! Please try again'
                        });
                    }

                });
        },
        loadFileFolders() {
            // this.$emit('loadFileFolders', this.folder.idFolders);
        },
        getAllUsers() {
            ApiService.get("api/file/user-list")
                .then(({
                    data
                }) => {
                    this.users = data.data;
                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                });
        },
        getSharedUsers(idFolders) {
            ApiService.setHeader();
            ApiService.get("api/folder/shared-users", idFolders)
                .then(({
                    data
                }) => {

                    var self = this;
                    setTimeout(function () {
                        self.sharedUsers = data?.data?.shared
                        self.shareFolderForm.fill({
                            idFolders: self.folder.idFolders,
                            userObj: []
                        });
                    }, 500);

                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                });
        },
        removeAccess(idFileShares) {
            this.$Progress.start();
            ApiService.setHeader();
            ApiService.delete("api/shared/remove-access/"+idFileShares)
                .then(({
                    data
                }) => {
                    this.getSharedUsers(this.folder.idFolders);
                    this.$Progress.finish();
                })
                .catch(({
                    response
                }) => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occurred! Please try again'
                    });
                });
        }
    },
    created() {
        console.log(this.page);
        this.getAllUsers();
    }
}
</script>
