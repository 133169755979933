<template>

</template>

<script>
    import ApiService from "../common/api.service";
    import JwtService from "../common/jwt.service";
    import store from '../store'
    export default {
        mounted () {

            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("api/auth/logout")
                    .then(({ data }) => {
                        JwtService.destroyToken();
                        window.localStorage.removeItem("userInfo");
                        window.localStorage.removeItem("settings");
                        store.commit('logoutUser')
                        Toast.fire({
                            icon: 'success',
                            title: 'User successfully signed out'
                        });
                        // context.commit(SET_AUTH, data.data);
                        window.location.href = "/login"
                        // this.$router.push({ name: 'login' })
                    })
                    .catch(({ response }) => {
                        console.log(response);
                        // context.commit(PURGE_AUTH);
                    //   context.commit(SET_ERROR, response.data.errors);
                    });
            } else {
                console.log("Else...");
                // context.commit(PURGE_AUTH);
            }
            return false;
            // localStorage.removeItem('token')
            // store.commit('logoutUser')
            // this.$router.push({ name: 'login' })
        }
    }
</script>
