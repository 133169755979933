import LogoutComponent from './components/Logout'
export default [
    { path: '/login', name: 'login', component: require('./components/Login.vue').default },
    { path: '/logout', name: 'logout', component: LogoutComponent, meta: { requiresAuth: true }},
    { path: '/dashboard', name: 'dashboard', meta: { requiresAuth: true }, component: require('./components/Dashboard.vue').default },
    { path: '/profile', meta: { requiresAuth: true }, component: require('./components/Profile.vue').default },
    { path: '/users', meta: { requiresAuth: true }, component: require('./components/Users.vue').default },
    { path: '/designation', meta: { requiresAuth: true }, component: require('./components/Designation.vue').default },
    { path: '/company', meta: { requiresAuth: true }, component: require('./components/Company.vue').default },
    { path: '/department', meta: { requiresAuth: true }, component: require('./components/Department.vue').default },
    { path: '/extension', meta: { requiresAuth: true }, component: require('./components/Extension.vue').default },
    { path: '/my-files', name: 'my-files', meta: { requiresAuth: true }, component: require('./components/MyFiles.vue').default },
    { path: '/shared-with-me', meta: { requiresAuth: true }, component: require('./components/Shared.vue').default },
    { path: '/trash', meta: { requiresAuth: true }, component: require('./components/Trash.vue').default },
    { path: '/search/:q?', name: 'search', meta: { requiresAuth: true }, component: require('./components/Search.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default }
];
