<template>
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <!-- Brand Logo -->
        <router-link to="/dashboard" class="brand-link">
            <img v-bind:src="'images/logo.png'" alt="The Logo" class="brand-image elevation-3" style="opacity: .8">
            <span class="brand-text font-weight-light">EDMS</span>
        </router-link>

        <!-- Sidebar -->
        <div class="sidebar">
            <!-- Sidebar user panel (optional) -->
            <router-link to="/profile">
                <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div class="image">
                        <img :src="AWS_URL+'uploads/users/'+photo" class="img-circle elevation-2" alt="User Image">
                    </div>
                    <div class="info">

                        {{name}}
                        <span class="d-block text-muted">
                            {{role}}
                        </span>
                    </div>
                </div>
            </router-link>
            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item">
                        <router-link to="/dashboard" class="nav-link">
                        <i class="nav-icon fas fa-tachometer-alt blue"></i>
                        <p> Dashboard </p>
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="$gate.isAdminOrUser()">
                        <router-link to="/my-files" class="nav-link" @click.native="forcePageReload('/my-files')">
                            <i class="fa fa-folder nav-icon blue"></i>
                            <p>My Files & Folders</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/shared-with-me" class="nav-link" @click.native="forcePageReload('/shared-with-me')">
                            <i class="fa fa-share-alt nav-icon blue"></i>
                            <p>Shared With Me</p>
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="currentUserId===1">
                        <router-link to="/trash" class="nav-link">
                            <i class="fa fa-trash nav-icon blue"></i>
                            <p>Trash</p>
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="$gate.isAdmin()">
                        <router-link to="/users" class="nav-link">
                        <i class="fa fa-users nav-icon blue"></i>
                        <p>Users</p>
                        </router-link>
                    </li>


                    <li class="nav-item has-treeview" v-if="$gate.isAdmin()">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-cog green"></i>
                            <p>
                                Settings
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">

                            <li class="nav-item">
                                <router-link to="/designation" class="nav-link">
                                    <i class="nav-icon fas fa-user green"></i>
                                    <p>Designation</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/department" class="nav-link">
                                    <i class="nav-icon fas fa-industry green"></i>
                                    <p>Department</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/company" class="nav-link">
                                    <i class="nav-icon fas fa-tags green"></i>
                                    <p>Company</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/extension" class="nav-link">
                                    <i class="nav-icon fas fa-list green"></i>
                                    <p>File Extensions</p>
                                </router-link>
                            </li>

                        </ul>
                    </li>
                    <li class="nav-item">
                        <router-link to="/logout" class="nav-link">
                            <i class="nav-icon fas fa-power-off red"></i>
                            <p>Logout</p>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
        <!-- /.sidebar -->

    </aside>
</template>

<script>
    import store from '../../store'
    export default {
        data(){
            return {
                currentUserId: store.state.userInfo.id,
                name: store.state.userInfo.first_name+' '+store.state.userInfo.last_name,
                email: store.state.userInfo.email,
                photo: store.state.userInfo?.photo ?? 'default.png',
                role: store.state.userInfo.role_name
            }
        },
        mounted() {
            console.log('Component mounted.')
        },
        methods: {
            //This method is used to force page reload
            forcePageReload(route) {
                console.log(route, "Attempting to change route");
    
                this.$router.push(route).then(() => {
                    console.log("Route changed successfully");
                    window.location.reload();
                }).catch(err => {
                    if (err.name !== "NavigationDuplicated") {
                        throw err; // If it's not the error we expect, throw it
                    }
                    console.log("Trying to navigate to the current route");
                    window.location.reload();
                });
                // window.location.href = route
            },
        },
        created() {

        }
    }
</script>

