import store from './store'
export default class Gate{

    isAdmin(){
        return store.state.userInfo.id_role == 1;
    }

    isUser(){
        return store.state.userInfo.id_role == 2;
    }

    isViewer(){
        return store.state.userInfo.id_role == 3;
    }

    isAdminOrUser(){
        if(store.state.userInfo.id_role == 1 || store.state.userInfo.id_role == 2){
            return true;
        }
    }
}

